import React, { useEffect, useRef, useState } from "react";
import { Container, Row } from "react-bootstrap";
import "./Terms&Conditions.css";
import instance, { decryptData } from "../Api";

const TermsConditions = (props) => {
  const [isMobile, setIsMobile] = useState(isMobileDevice());
  const [htmlContent, setHtmlContent] = useState("");
  const [title, setTitle] = useState("");
  const htmlContentRef = useRef(null);

  function isMobileDevice() {
    return window.matchMedia("(max-width: 800px)").matches;
  }

  useEffect(() => {
    function handleResize() {
      setIsMobile(isMobileDevice());
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile]);

  useEffect(() => {
    const fetchHtmlContent = async () => {
      try {
        const response = await instance.post("/pages");
        const dataRes = await decryptData(response.data);
        // console.log(dataRes.pages, "......PAGESSSS");
        // console.log(dataRes.pages[2].policy, "......PAGESSSS");
        setTitle(dataRes.pages[2].heading);
        setHtmlContent(dataRes.pages[2].policy);
      } catch (error) {
        console.error("Error fetching HTML content:", error);
      }
    };
    fetchHtmlContent();
  }, []);

  return (
    <div>
      {/* <Container> */}
      {isMobile ? (
        <>
          <div
            class="term-posts"
            style={{
              marginTop: "5rem",
              marginLeft: "10px",
              textAlign: "justify",
            }}
          >
            <h3>Terms of Services</h3>

            {htmlContent && (
              <div
                ref={htmlContentRef}
                dangerouslySetInnerHTML={{ __html: htmlContent }}
                style={{
                  maxWidth: "100%", // Ensure it doesn't exceed the screen width
                  width: "120mm", // Set the desired width
                  padding: "10px", // Optional padding
                  boxSizing: "border-box", // Include padding and border in the element's total width and height
                  overflowWrap: "break-word", // Handle long words or URLs
                }}
              />
            )}
          </div>
        </>
      ) : (
        <>
          <Row>
            <div className="col-md-1"></div>
            <div
              className="col-md-10"
              style={{ marginTop: "4rem", marginLeft: "18rem" }}
            >
              {/* {title && <h2>{title}</h2>} */}
              <div
                className="heading-dotted-bill"
                style={{
                  marginLeft: "-3rem",
                  marginBottom: "20px",
                  textTransform: "none",
                }}
              >
                Terms of Services
              </div>
              {htmlContent && (
                <div
                  ref={htmlContentRef}
                  dangerouslySetInnerHTML={{ __html: htmlContent }}
                  style={{ width: isMobile ? "120mm" : "95%", padding: "" }}
                />
              )}

              {/* <div class="term-posts" style={{ marginTop: "10rem" }}>
            <div class="term-posts-inner">
              <div class="term-post">
                <h3>Terms and Conditions</h3>
                <div class="term-other-content">
                  <div class="term-para">
                    Welcome to UpNetCloud.com, a product of Azura Digital World.
                    These terms and conditions outline the rules and regulations
                    for the use of UpNetCloud's Website and Services.
                  </div>
                  <div class="term-para-title">
                    By accessing this website, we assume you accept these terms
                    and conditions. Do not continue to use UpNetCloud.com if you
                    do not agree to take all of the terms and conditions stated
                    on this page.
                  </div>
                  <div class="term-para">
                    <div>
                      The following terminology applies to these Terms and
                      Conditions, Privacy Statement and Disclaimer Notice and
                      all Agreements: "Client", "You" and "Your" refers to you,
                      the person log on this website and compliant to the
                      Company's terms and conditions. "The Company",
                      "Ourselves", "We", "Our" and "Us", refers to Azura Digital
                      World. "Party", "Parties", or "Us", refers to both the
                      Client and ourselves. All terms refer to the offer,
                      acceptance and consideration of payment necessary to
                      undertake the process of our assistance to the Client in
                      the most appropriate manner for the express purpose of
                      meeting the Client's needs in respect of provision of the
                      Company's stated services, in accordance with and subject
                      to, prevailing law of India. Any use of the above
                      terminology or other words in the singular, plural,
                      capitalization and/or he/she or they, are taken as
                      interchangeable and therefore as referring to same.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
            </div>
          </Row>
        </>
      )}

      {/* </Container> */}
    </div>
  );
};

export default TermsConditions;
