import React, { useEffect, useState } from "react";
import "./ToggleSwitch.css";
import { Button, Col, Container, Row } from "react-bootstrap";
import "./MyMachinePage.css";
import { useAuth } from "../../AuthContext";
import instance, {
  apiDecrypteRequest,
  apiEncryptRequest,
  decryptData,
  getTimeDifference,
} from "../../Api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../common/Loader";
import { FaX } from "react-icons/fa6";
import toast, { Toaster } from "react-hot-toast";
import AppToast from "../../AppToast";

import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { useDropdown } from "../Header";

// import RangeSlider from "./common/RangeSlider";

const MyMachinePage = () => {
  const navigate = useNavigate();
  function isMobileDevice() {
    return window.matchMedia("(max-width: 800px)").matches;
  }

  const { smuser, isLoginByParentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [vNCinfo, setVNCinfo] = useState(null);
  const [machineData, setMachineData] = useState(null);
  const [isOn, setIsOn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [vncPopup, SetVncPopup] = useState(false);
  const [editPopup, SetEditPopup] = useState(false);
  const [deletePopup, SetDeletePopup] = useState(false);

  const [showPlaceholder, SetShowPlaceholder] = useState(false);

  const [progressingVM, setProgressingVM] = useState(null);
  const [deleteById, setDeleteById] = useState(null);
  const [countdown, setCountdown] = useState(null);
  const [timerStarted, setTimerStarted] = useState(false);
  const [shownOnce, setShownOnce] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [editMachineId, setEditMachineId] = useState("");
  const toggleSwitch = (data) => {
    //console.log(data);
    if (data.status === "on") {
      OnMachine(data.id);
    } else {
      OffMachine(data.id);
    }
  };
  const [machineTime, setMachineTime] = useState("");
  const [machineActiveTime, setMachineActiveTime] = useState("");
  const [seconds, setSeconds] = useState(51);
  const [machineUser, setMachineUser] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [deletingMachine, setDeletingMachine] = useState("");

  const [inProcessMachine, setInProcessMachine] = useState(false);
  const [showTermsCondition, setShowTermsCondition] = useState(false);
  const [machineToDelete, setMachineToDelete] = useState(null);
  const [slaDesc, setSLADesc] = useState(null);
  const [slaPdf, setSLAPdf] = useState(null);
  const [slaId, setSLAid] = useState(null);
  const [myMachineId, setMyMachineId] = useState(null);
  const [isProfileVerified, setProfileVerified] = useState(null);
  const { dropdownValue } = useDropdown();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    // window.scrollTo(0, 0);
    let countdown;
    if (isActive && seconds > 0) {
      countdown = setInterval(() => {
        setSeconds(seconds - 1);
      }, 1000);
    } else if (seconds === 0) {
      setShownOnce(true);
      clearInterval(countdown);
      GetMachines();
    }

    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);

    // Clean up the interval on component unmount
    // return () => clearInterval(countdown);
    return () => {
      clearInterval(countdown);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isActive, seconds]);

  useEffect(() => {
    // Restore the scroll position after re-render
    window.scrollTo(0, scrollPosition);
  }, [scrollPosition]);

  const generatePdf = () => {
    console.log("Generate PDF:");
    const pdf = new jsPDF();
    const pageHeight = pdf.internal.pageSize.height;
    const margin = 10;
    const lineHeight = 10;
    const maxLinesPerPage = Math.floor((pageHeight - 2 * margin) / lineHeight);
    const newData = `${""} \n Name: ${
      smuser.name
    }, \n Server IP: 10.00.00.000, \n User IP: 103.240.168.48, \n Email: ${
      smuser.email
    }, \n Mobile: ${smuser.phone} \n ${window.navigator.userAgent} \n`;

    const lines = newData.split("\n");
    let currentPage = 1;
    let currentLine = 0;
    let textToPrint = "";

    lines.forEach((line) => {
      const words = line.split(" ");
      let currentLineWidth = 0;

      words.forEach((word) => {
        const wordWidth = pdf.getTextWidth(word + " ");
        if (
          currentLineWidth + wordWidth >
          pdf.internal.pageSize.width - 2 * margin
        ) {
          pdf.text(textToPrint, margin, margin + currentLine * lineHeight);
          currentLineWidth = 0;
          textToPrint = "";
          currentLine++;
          if (currentLine >= maxLinesPerPage) {
            pdf.addPage();
            currentLine = 0;
            currentPage++;
          }
        }
        textToPrint += word + " ";
        currentLineWidth += wordWidth;
      });

      pdf.text(textToPrint, margin, margin + currentLine * lineHeight);
      currentLine++;
      if (currentLine >= maxLinesPerPage) {
        pdf.addPage();
        currentLine = 0;
        currentPage++;
      }
      textToPrint = "";
    });

    const blob = pdf.output("blob");
    const formData = new FormData();
    formData.append("file", blob, "SLA-agreement.pdf");
    console.log("Form Data:", formData);
    setSLAPdf(formData);

    //uploadPdf(formData);
  };

  const generatePdf1 = () => {
    const pdf = new jsPDF();
    const pageHeight = pdf.internal.pageSize.height;
    const margin = 10;
    const lineHeight = 10;
    const maxLinesPerPage = Math.floor((pageHeight - 2 * margin) / lineHeight);
    const newData = `${""} \n Name: ${
      smuser.name
    }, \n Server IP: 10.00.00.000, \n User IP: 103.240.168.48, \n Email: ${
      smuser.email
    }, \n Mobile: ${smuser.phone} \n ${window.navigator.userAgent} \n`;

    const lines = newData.split("\n");
    let currentPage = 1;

    let currentLine = 0;
    let textToPrint = "";
    lines.forEach((line) => {
      const words = line.split(" ");
      let currentLineWidth = 0;
      words.forEach((word) => {
        const wordWidth = pdf.getTextWidth(word + " ");
        if (
          currentLineWidth + wordWidth >
          pdf.internal.pageSize.width - 2 * margin
        ) {
          pdf.text(textToPrint, margin, margin + currentLine * lineHeight);
          currentLineWidth = 0;
          textToPrint = "";
          currentLine++;
          if (currentLine >= maxLinesPerPage) {
            pdf.addPage();
            currentLine = 0;
            currentPage++;
          }
        }
        textToPrint += word + " ";
        currentLineWidth += wordWidth;
      });
      pdf.text(textToPrint, margin, margin + currentLine * lineHeight);
      currentLine++;
      if (currentLine >= maxLinesPerPage) {
        pdf.addPage();
        currentLine = 0;
        currentPage++;
      }
      textToPrint = "";
    });
    console.log("pdf Name:", pdf);
    const formData = new FormData();
    formData.append("file", pdf);
    //setSLAPdf(formData);
    //pdf.save("SLA-agreement.pdf");
  };

  useEffect(() => {
    // Fetch the stored time from localStorage
    const storedMachineTime = localStorage.getItem("machineTime");
    console.log(storedMachineTime, "TIME<<<<");
    setMachineTime(storedMachineTime);
    // setTimeout(() => {
    //   UpdateStatus();
    // }, 50000);
    // Calculate active time by adding 15 minutes to stored time
    if (storedMachineTime) {
      const timeObj = new Date(storedMachineTime);
      timeObj.setMinutes(timeObj.getMinutes() + 30);
      const activeTime = timeObj.toISOString();
      console.log(activeTime, "TIME<<<<");
      setMachineActiveTime(activeTime);

      // Optionally, store machineActiveTime in localStorage
      // localStorage.setItem("machineActiveTime", activeTime);
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      UpdateVmStatus();
      UpdateStatus();
      GetMachines();
    }, 30000); // 60000 milliseconds = 1 minute

    // Cleanup the interval on component unmount
    const intervalDate = setInterval(() => {
      const currentTime = new Date().getTime();

      // Check if machineActiveTime is set and if current time matches it
      if (machineActiveTime && currentTime >= machineActiveTime) {
        handleTimeMatch();
        clearInterval(interval); // Stop checking once condition is met
      }
    }, 1000); // Check every second

    return () => {
      clearInterval(interval);
      clearInterval(intervalDate);
    };

    // return () => clearInterval(interval);
  }, [machineActiveTime]);

  useEffect(() => {
    let intervalId;

    if (countdown === 0) {
      // Redirect or perform any action after countdown finishes
      window.location.href = "/vm-machine";
    }

    if (timerStarted) {
      intervalId = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown === 0) {
            clearInterval(intervalId);
            return 0;
          } else {
            return prevCountdown - 1;
          }
        });
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [timerStarted, countdown]);

  const RebuildMachine = async (machineData) => {
    // handleRedirect();
    console.log(machineData, "machineData");
    if (machineData == null) {
      toast((t) => (
        <AppToast
          id={t.id}
          message={
            "Oops! Your wallet balance is low, Please Add Money to create machine"
          }
          isMobile={isMobile}
        />
      ));
    } else {
      setLoading(true);
      const reBuildPaylod = {
        user_id: smuser.id,
        request_id: machineData.request_id,
        machine_price: machineData.machine_o_rate,
        plan_time: machineData.plan_time,
        vm_template: machineData.vm_template,
        osiddata: machineData.osiddata,
        name: machineData.vm_name,
        // vm_config_category:
        // config: machineData.vm_config_type
        cpu: machineData.cpu,
        ram: machineData.ram,
        hard_disk: machineData.hard_disk,
        data_transfer: machineData.data_transfer,
      };
      console.log(reBuildPaylod, "reBuildPaylod");
      // const payload = {
      //   uuid: newMahineOs,
      //   config: newMahineConfigId,
      //   name: newMachineName,
      //   machine_val: newMahineOs, //drop down id
      //   machine_price: finalAmount,
      //   plan_time: newMachineTime, //1
      //   user_id: smuser.id,
      // };
      // console.log(payload, "CreateMachine_Li");
      if (smuser.platform_status == "1") {
        try {
          // First API call to encrypt the request
          const encryptedResponse = await apiEncryptRequest(reBuildPaylod);
          // //console.log(encryptedResponse, "=encryptedResponse");

          // Second API call to login with encrypted response
          const createMachineRes = await instance.post(
            "/vm/rebuild_new",
            encryptedResponse
          );
          // //console.log(createMachineRes.data, "====createMachineRes");

          // Third API call to decrypt the login response
          const finalResponse = await decryptData(createMachineRes.data);
          const Details = finalResponse;
          // //console.log(Details.status, "==!==!==Details");
          if (Details.status) {
            window.location.href = "/vm-machine";
          }
        } catch (error) {
          toast((t) => (
            <AppToast
              id={t.id}
              message={
                "Oops! Something went wrong while fetching the data. Please try again later or contact support if the issue persists."
              }
              isMobile={isMobile}
            />
          ));
        }
      } else {
        try {
          setTimeout(() => {
            window.location.href = "/vm-machine";
            setLoading(false);
          }, 3000);
          // First API call to encrypt the request
          const encryptedResponse = await apiEncryptRequest(reBuildPaylod);
          const createMachineRes = await instance.post(
            "/vm/rebuild_new",
            encryptedResponse
          );
          // handleRedirect();
          const finalResponse = await decryptData(createMachineRes.data);
          const Details = finalResponse;
          console.log(Details, "==!==!==/vm/rebuild_new");
          // if (Details.status) {
          //   alert("New machine created successfully ");
          //   window.location.href = "/vm-machine";
          // }
          // if (Details.code == 999) {
          //   alert("Please try again later, Unable to create Machine!");
          // }
          // window.location.href = "/vm-machine";
        } catch (error) {
          toast((t) => (
            <AppToast
              id={t.id}
              message={
                "Oops! Something went wrong while fetching the data. Please try again later or contact support if the issue persists."
              }
              isMobile={isMobile}
            />
          ));
        }
      }
      // console.log(payload, "CREATE MACHINE PAYLOAD");
    }
  };

  const handleTimeMatch = () => {
    console.log("Machine active time reached!");
    GetMachines();
    // Replace with your function call logic
  };

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     const currentTime = new Date().getTime();

  //     // Check if machineActiveTime is set and if current time matches it
  //     if (machineActiveTime && currentTime >= machineActiveTime) {
  //       handleTimeMatch();
  //       clearInterval(interval); // Stop checking once condition is met
  //     }
  //   }, 1000); // Check every second

  //   return () => clearInterval(interval); // Clean up interval on component unmount or re-render
  // }, [machineActiveTime]);

  const GetMachines = async () => {
    setLoading(true);
    const payload = {
      user_id: smuser.id,
    };
    try {
      // First API call to encrypt the request
      const encryptedResponse = await apiEncryptRequest(payload);
      //console.log(encryptedResponse, "=encryptedResponse");

      // Second API call to login with encrypted response
      const loginUserResponse = await instance.post(
        "/machines",
        encryptedResponse
      );
      //console.log(loginUserResponse.data, "====loginUserResponse");

      // Third API call to decrypt the login response
      const loginResponse = await decryptData(loginUserResponse.data);
      console.log(loginResponse, "GetMachines");
      const userDetails = loginResponse;
      const user = loginResponse.user;
      const vm = loginResponse.vm;
      console.log(user, "user_GetMachines");
      setMachineUser(user);
      // //console.log(user, "==!==!==user");
      //console.log(vm, "==!==!==vm");
      const vmArray = Object.keys(vm).map((key) => vm[key]);
      //console.log(vmArray, "==!==!==vvmArraym");
      if (vmArray.find((item) => item.current_action_status === "PROCESSING")) {
        const progressVM = vmArray.find(
          (item) => item.current_action_status === "PROCESSING"
        );
        // console.log(progressVM.vm_id);
        setIsActive(true);
        setProgressingVM(progressVM.vm_id);
      }

      vmArray.length > 0 ? SetShowPlaceholder(false) : SetShowPlaceholder(true);
      setMachineData(vmArray);
      //UpdateStatus();
      // const vmArray = Object.keys(vm).map((key) => vm[key]);
      // localStorage.setItem("NEW_USER", JSON.stringify(userDetails));
      // window.location.href = "/";
      GetMyDetails();
    } catch (error) {
      console.error("Error during the login process:", error);
    }
    setLoading(false);
  };

  const UpdateVmStatus = async () => {
    const payload = {
      user_id: smuser.id,
    };
    try {
      // First API call to encrypt the request
      const encryptedResponse = await apiEncryptRequest(payload);
      //console.log(encryptedResponse, "=encryptedResponse");

      // Second API call to login with encrypted response
      const loginUserResponse = await instance.post(
        "/updatevmstatus",
        encryptedResponse
      );
      //console.log(loginUserResponse.data, "====loginUserResponse");

      // Third API call to decrypt the login response
      const loginResponse = await decryptData(loginUserResponse.data);

      console.log(loginResponse, "updatevmstatus");
    } catch (error) {
      console.error("Error during the login process:", error);
    }
    setLoading(false);
  };

  const UpdateStatus = async () => {
    const payload = {
      user_id: smuser.id,
    };
    try {
      // First API call to encrypt the request
      const encryptedResponse = await apiEncryptRequest(payload);
      //console.log(encryptedResponse, "=encryptedResponse");

      // Second API call to login with encrypted response
      const loginUserResponse = await instance.post(
        "/updatestatus",
        encryptedResponse
      );
      //console.log(loginUserResponse.data, "====loginUserResponse");
      // Third API call to decrypt the login response
      const loginResponse = await decryptData(loginUserResponse.data);

      console.log(loginResponse, "updatevmstatus");
    } catch (error) {
      console.error("Error during the login process:", error);
    }
    setLoading(false);
  };
  const deleteMachine = async (machineId) => {
    setDeletingMachine(machineId.vm_id);
    toast((t) => (
      <AppToast
        id={t.id}
        message={"Your machine deletion process has been started!"}
        isMobile={isMobile}
      />
    ));
    // setLoading(true);
    const payload = {
      user_id: smuser.id,
      vm_id: machineId.vm_id,
      id: machineId.id,
    };
    console.log(payload, "------- deleteMachine");
    try {
      // First API call to encrypt the request
      const encryptedResponse = await apiEncryptRequest(payload);
      //console.log(encryptedResponse, "=encryptedResponse");

      // Second API call to login with encrypted response
      const distroyResponse = await instance.post(
        "/vm/distroy",
        encryptedResponse
      );
      // console.log(distroyResponse.data, "====distroyResponse");

      // Third API call to decrypt the login response
      const Response = await decryptData(distroyResponse.data);
      console.log(Response.data, "====distroyResponse");
      const userDetails = Response;
      if (userDetails.status) {
        // console.log(userDetails, "DESTROY RESPONSE");
        GetMachines();
      } else {
        // toast();
        toast((t) => (
          <AppToast
            id={t.id}
            message={"Unable To Delete Machine Please Try Again Later!"}
            isMobile={isMobile}
          />
        ));
      }
    } catch (error) {
      console.error("Error during the login process:", error);
    }
    setLoading(false);
  };

  const VMC = async (machineId) => {
    setLoading(true);
    const payload = {
      user_id: smuser.id,
      vm_id: machineId,
    };
    //console.log(payload, "------- VMC");

    const res = {
      status: true,
      info: {
        port: "5909",
        ip: "175.111.97.101",
        password: "test123",
      },
      code: "200",
    };
    try {
      const vncResponse = await instance.post("vm/vnc", payload);
      //console.log(vncResponse.data, "====VMC");
      if (vncResponse.data.status) {
        setLoading(false);
        //console.log(vncResponse.data.url);
        const pushUrl = `${vncResponse.data.url}`;
        window.location.href = pushUrl;
        // SetVncPopup(true);
        // const info = vncResponse.data.info;
        // //console.log(info, "INFOOOO");
        // setVNCinfo(info);
      } else {
      }
    } catch (error) {
      console.error("Error during the login process:", error);
    }
    setLoading(false);
  };

  const OnMachine = async (machineId) => {
    setLoading(true);
    const payload = {
      user_id: smuser.id,
      vm_id: machineId,
    };
    // //console.log(payload, "ONNNN");
    try {
      // First API call to encrypt the request
      const encryptedResponse = await apiEncryptRequest(payload);
      //console.log(encryptedResponse, "=encryptedResponse");

      // Second API call to login with encrypted response
      const distroyResponse = await instance.post("/vm/on", encryptedResponse);
      //console.log(distroyResponse.data, "====distroyResponse");

      // Third API call to decrypt the login response
      const Response = await decryptData(distroyResponse.data);
      const userDetails = Response;
      console.log(userDetails, "ONMACHINE");
      if (userDetails.status) {
        GetMachines();
      }
    } catch (error) {
      console.error("Error during the login process:", error);
    }
    setLoading(false);
  };

  const OffMachine = async (machineId) => {
    setLoading(true);
    const payload = {
      user_id: smuser.id,
      vm_id: machineId,
    };
    // //console.log(payload, "ONNNN");
    try {
      // First API call to encrypt the request
      const encryptedResponse = await apiEncryptRequest(payload);
      //console.log(encryptedResponse, "=encryptedResponse");

      // Second API call to login with encrypted response
      const distroyResponse = await instance.post("/vm/off", encryptedResponse);
      //console.log(distroyResponse.data, "====distroyResponse");

      // Third API call to decrypt the login response
      const Response = await decryptData(distroyResponse.data);
      const userDetails = Response;
      console.log(userDetails, "OFFMACHINE");
      if (userDetails.status) {
        GetMachines();
      }
    } catch (error) {
      console.error("Error during the login process:", error);
    }
    setLoading(false);
  };

  const slaManagement = async (machineId) => {
    setLoading(true);
    const payload = {
      user_id: smuser.id,
      //vm_id: machineId,
    };
    console.log(payload, "slaManagement Payload");
    try {
      // First API call to encrypt the request
      const encryptedResponse = await apiEncryptRequest(payload);
      //console.log(encryptedResponse, "=encryptedResponse");

      // Second API call to login with encrypted response
      const distroyResponse = await instance.post(
        "/slamgnt",
        encryptedResponse
      );
      //console.log(distroyResponse.data, "====distroyResponse");

      // Third API call to decrypt the login response
      const Response = await decryptData(distroyResponse.data);
      console.log(Response, "SlaManagment Response");
      if (Response.status) {
        setShowTermsCondition(true);
        setSLADesc(Response.sla);
        setSLAid(Response.sla_id);
        setMyMachineId(machineId);
        // generatePdf();
      }
    } catch (error) {
      console.error("Error during the process:", error);
    }
    setLoading(false);
  };

  const agreeSLAPdf = async (machineid) => {
    setLoading(true);
    const payload = {
      user_id: smuser.id,
      vm_id: machineid,
      sla_id: slaId,
      finger_print: window.navigator.userAgent,
      //file: slaPdf
    };
    console.log(payload, "agreeSLAPdf Payload");
    try {
      // First API call to encrypt the request
      const encryptedResponse = await apiEncryptRequest(payload);
      //console.log(encryptedResponse, "=encryptedResponse");

      const termsacceptResponse = await instance.post(
        "/termsaccept",
        encryptedResponse
      );
      //console.log(termsacceptResponse.data, "====termsacceptResponse");

      const decTermsacceptResponseResponse = await decryptData(
        termsacceptResponse.data
      );
      console.log(decTermsacceptResponseResponse, "TermsacceptResponse");
      if (decTermsacceptResponseResponse.success) {
        GetMachines();
        toast((t) => (
          <AppToast id={t.id} message={"SLA terms accepted successfully."} />
        ));
      } else {
        toast((t) => (
          <AppToast
            id={t.id}
            message={decTermsacceptResponseResponse.message}
          />
        ));
      }
    } catch (error) {
      console.error("Error during the login process:", error);
    }
    setLoading(false);
  };

  const [isMobile, setIsMobile] = useState(isMobileDevice());
  const [activeButton, setActiveButton] = useState("");
  const innerButtons = [
    "Performance Issue",
    "Network Issue",
    "Installation Issue",
    "Sales",
    "Billing Query",
    "Other",
  ];

  const changeCurrency = (value) => {
    // Add your currency change logic here
    //console.log("Currency changed to:", value);
  };
  useEffect(() => {
    GetMachines();

    function handleResize() {
      setIsMobile(isMobileDevice());
    }
    setTimeout(() => {
      GetMachines();
    }, 5000);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile]);

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const GetMyDetails = async () => {
    //setLoading(true);
    const payload = {
      user_id: smuser.id,
    };
    try {
      const loginUserResponse = await instance.post("/editprofile", payload);
      //console.log(loginUserResponse.data, "====/vm/monitor");
      const User = loginUserResponse.data.user;

      if (
        User.gst_verify == 1 ||
        User.aadhar_verify == 1 ||
        User.pan_verify == 1 ||
        User.driving_verify == 1 ||
        User.ciib_verify == 1
      ) {
        setProfileVerified(true);
      }

      console.log(User, "====/VMMMMMM");
    } catch (error) {}
    //setLoading(false);
  };

  const filterByLocation = (dataArray, dropValue) => {
    // console.log(dropValue, "drop");
    if (dropValue === "ALL") {
      return dataArray;
    } else {
      return dataArray.filter((item) => item.location === dropValue);
    }
  };

  const featureListStyle = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    padding: "20px",
  };
  return (
    <div
      style={{
        width: "100%",
        minHeight: "65rem",
        position: "relative",
        backgroundImage: isMobile ? `url(./main-bg.jpg)` : `url(./main-bg.jpg)`,
        backgroundSize: "cover",
        // backgroundPosition: "center",
        // backgroundColor: "#141414",
        backgroundRepeat: "round",
        backgroundBlendMode: "overlay",
      }}
    >
      {loading && (
        <div className="loading-overlay" style={{ zIndex: "99999999999999" }}>
          <Loader isLoading={loading} />
          {/* <Spinner animation="border" /> */}
        </div>
      )}
      {showTermsCondition && (
        <div style={{ display: "grid", justifyItems: "center" }}>
          <div
            style={{
              backgroundSize: "cover",
              backgroundImage: isMobile
                ? `url(/main-bg.jpg)`
                : `url(/main-bg.jpg)`,
              top: "10%",
              // left: "30%",
              position: "absolute",
              zIndex: "99",
              width: isMobile ? "90%" : "35%",
              backdropFilter: "blur(5px)",
              height: "35rem",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)", // Box shadow added
              borderRadius: "12px", // Assuming you want rounded corners
              border: "2px solid #e97730",
            }}
          >
            <div style={{ display: "grid", justifyItems: "center" }}>
              <button
                style={{
                  color: "#035189",
                  position: "absolute",
                  right: "0px",
                  border: "none",
                  backgroundColor: "transparent",
                  width: "20px",
                  height: "20px",
                }}
                onClick={() => setShowTermsCondition(!showTermsCondition)}
              >
                X
              </button>
              <h4 style={{ marginTop: "10px" }}>SLA Agreement</h4>
              <div
                style={{
                  height: "25rem",
                  overflowY: "scroll",
                  padding: "30px",
                  borderRadius: "8px",
                  border: "1px solid #e97730",
                }}
                id="pdfContent"
              >
                <p>{slaDesc}</p>
              </div>
              <div style={{ display: "grid" }}>
                {/* <p>
                  
                  IP:103.240.168.48 <br />
                  Email: {smuser.email}
                  <br />
                  Mobile: {smuser.phone}
                </p> */}
                <p></p>
              </div>

              <button
                style={{
                  color: "white",
                  width: "8rem",
                  height: "40px",
                  backgroundColor: "#035189",
                  borderRadius: "25px",
                  border: "2px solid #ffff",
                  outline: "2px solid #035189",
                  marginTop: "25px",
                }}
                onClick={() => {
                  //generatePdf(machineData.request_id);
                  setShowTermsCondition(!showTermsCondition);
                  agreeSLAPdf(myMachineId);
                }}
              >
                I AGREE
                {/* I Agree all the terms & condition of UPNETCLOUD */}
              </button>
            </div>
          </div>
        </div>
      )}
      {isMobile ? (
        <div className="" style={{ height: "50rem" }}>
          {vncPopup && (
            <div
              style={{
                content: "",
                top: "0",
                left: "0",
                right: "0",
                position: "fixed",
                width: "100%",
                height: "100vh",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                zIndex: "999999999999",
              }}
            >
              <div
                style={{
                  position: "relative",
                }}
              >
                <div>
                  <img
                    src="/admin/images/admin/transaction-failed/transaction-failed-bg.png"
                    style={{
                      width: "25rem",
                      height: "15rem",
                      position: "absolute",
                      marginTop: "45%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  />
                  {/* inner box */}
                  <div
                    style={{
                      position: "relative",
                      zIndex: "1",
                      padding: "2.5rem 1.5rem",
                    }}
                  >
                    {/* Close Button */}
                    <div onClick={() => SetVncPopup(false)}>
                      {" "}
                      <FaX
                        style={{
                          position: "absolute",
                          top: "15%",
                          right: "5%",
                          color: "#fff",
                          display: "inline-block",
                          fontSize: "1.5rem",
                        }}
                      />
                    </div>
                    {/* header */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {vNCinfo && (
                        <div
                          style={{
                            position: "relative",
                            marginLeft: "20%",
                            marginTop: "23%",
                          }}
                        >
                          <span
                            style={{
                              color: "#154e7a",
                              fontSize: "24px",
                              fontWeight: "600",
                            }}
                          >
                            PORT : {vNCinfo.port} <br />
                            IP : {vNCinfo.ip}
                            <br />
                            PASSWORD : {vNCinfo.password}
                            <br />
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="heading-dotted-machine">
            VM List <span></span>
          </div>
          {machineData && machineData.length > 0 ? (
            <>
              {editPopup && (
                <div
                  style={{
                    top: "10%",
                    left: "3%",
                    position: "absolute",
                    zIndex: "9",
                    width: "27rem",
                    backdropFilter: "blur(5px)",
                    height: "20rem",
                    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)", // Box shadow added
                    borderRadius: "12px", // Assuming you want rounded corners
                    border: "2px solid #e97730",
                  }}
                >
                  <button
                    style={{
                      zIndex: "9",
                      position: "absolute",
                      backgroundColor: "transparent",
                      border: "none",
                      right: "0",
                    }}
                    onClick={() => SetEditPopup(!editPopup)}
                  >
                    <FaX
                      style={{
                        marginBottom: "2px",
                        color: "#e97730",
                        display: "inline-block",
                        fontSize: "19px",
                      }}
                    />
                  </button>{" "}
                  <div
                    style={{
                      position: "relative",
                      marginLeft: "10%",
                      marginTop: "20%",
                      display: "flex",
                      flexWrap: "nowrap",
                      flexDirection: "row",
                      gap: "3%",
                    }}
                  >
                    <div>
                      <figure
                        style={{
                          width: "100px",
                          height: "100px",
                          background: "rgb(233, 119, 48)",
                          borderRadius: "50%",

                          objectFit: "cover",

                          margin: "auto",
                          padding: "20px",
                          outline: "3px solid rgb(233, 119, 48)",
                          border: "5px solid white",
                        }}
                      >
                        <img
                          src={"/images/admin/01-home/cpu.svg"}
                          alt={""}
                          style={{
                            marginLeft: "-5px",
                            width: "60px",
                            height: "60px",
                          }}
                        />
                      </figure>
                      <div
                        style={{
                          zIndex: "10",
                          top: "95px",
                          left: "70px",
                          position: "absolute",
                          width: "20px",
                          height: "7px",
                          backgroundColor: "#fff",
                        }}
                      ></div>
                      <button
                        style={{
                          width: "10rem",
                          marginTop: "2px",
                          zIndex: "9",
                          position: "relative",
                          // marginTop: "15%",
                          // left: "20%",
                          fontWeight: "700",
                          color: "white",
                          height: "55px",

                          // width: "10rem",
                          backgroundColor: "#e97730",
                          outline: "4px solid #e97730",
                          border: "4px solid #ffff",
                          borderColor: "white",
                          borderRadius: "30px",
                        }}
                        onClick={() =>
                          machineData.sla_status == 1
                            ? navigate("/edit-vm", {
                                state: {
                                  machineData: editMachineId,
                                  editType: "os",
                                },
                              })
                            : !isProfileVerified
                            ? navigate("/edit-profile")
                            : slaManagement(editMachineId)
                        }
                      >
                        {" "}
                        Edit OS
                      </button>
                    </div>
                    <div>
                      {" "}
                      <figure
                        style={{
                          width: "100px",
                          height: "100px",
                          background: "rgb(233, 119, 48)",
                          borderRadius: "50%",
                          objectFit: "cover",
                          margin: "auto",
                          padding: "20px",
                          outline: "3px solid rgb(233, 119, 48)",
                          border: "5px solid white",
                        }}
                      >
                        <img
                          src={"/admin/images/admin/menu/vm-white.png"}
                          alt={""}
                          style={{
                            marginLeft: "-5px",
                            width: "60px",
                            height: "60px",
                          }}
                        />
                      </figure>{" "}
                      <div
                        style={{
                          zIndex: "10",
                          top: "96px",
                          right: "123px",
                          position: "absolute",
                          width: "20px",
                          height: "7px",
                          backgroundColor: "#ffff",
                        }}
                      ></div>
                      <button
                        style={{
                          width: "10rem",
                          marginTop: "2px",
                          zIndex: "9",
                          position: "relative",
                          // marginTop: "45%",
                          // left: "20%",
                          fontWeight: "700",
                          color: "white",
                          height: "55px",

                          // width: "10rem",
                          backgroundColor: "#e97730",
                          outline: "4px solid #e97730",
                          border: "4px solid #ffff",
                          borderColor: "white",
                          borderRadius: "30px",
                        }}
                        onClick={() =>
                          navigate("/edit-vm", {
                            state: {
                              machineData: editMachineId,
                              editType: "config",
                            },
                          })
                        }
                      >
                        Edit Config
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {deletePopup && (
                <div className="delete-popup-div-1">
                  <button
                    style={{
                      zIndex: "9",
                      position: "absolute",
                      backgroundColor: "transparent",
                      border: "none",
                      right: "0",
                    }}
                    onClick={() => SetDeletePopup(!deletePopup)}
                  >
                    <FaX
                      style={{
                        marginBottom: "2px",
                        color: "#e97730",
                        display: "inline-block",
                        fontSize: "19px",
                      }}
                    />
                  </button>{" "}
                  <h5
                    style={{
                      color: "#09528a",
                      textAlign: "center",
                      position: "absolute",
                      marginTop: "5rem",
                    }}
                  >
                    Do you Really Want to DELETE the Machine ?
                  </h5>
                  <div
                    style={{
                      position: "relative",
                      marginLeft: "10%",
                      marginTop: "45%",
                      display: "flex",
                      flexWrap: "nowrap",
                      flexDirection: "row",
                      gap: "3%",
                    }}
                  >
                    <div>
                      <button
                        className="delete-popup-yes-btn-1"
                        onClick={() => {
                          SetDeletePopup(false);
                          deleteMachine(machineToDelete);
                        }}
                      >
                        YES
                      </button>
                    </div>
                    <div>
                      {" "}
                      <button
                        className="delete-popup-no-btn-1"
                        onClick={() => SetDeletePopup(!deletePopup)}
                      >
                        NO
                      </button>
                    </div>
                  </div>
                </div>
              )}
              <div className="input-container input-container-1">
                <input
                  type="text"
                  name="search"
                  className="input-signup input-tickets"
                  placeholder="Search Machine"
                  value={searchText}
                  style={{
                    fontSize: "18px",
                    color: "black",
                    textAlign: "center",
                    width: "10px",
                  }}
                  onChange={(e) => setSearchText(e.target.value)}
                />
                {searchText && (
                  <button
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                    }}
                    onClick={() => setSearchText("")}
                  >
                    <FaX
                      style={{
                        marginBottom: "2px",
                        color: "#154e7a",
                        display: "inline-block",
                        fontSize: "19px",
                      }}
                    />
                  </button>
                )}
              </div>
              <div
                className="wallet-container"
                style={{
                  border: "none",
                }}
              >
                <div className="wallet-box">
                  {machineData &&
                    machineData
                      .filter((item) =>
                        item.vm_name
                          .toLowerCase()
                          .includes(searchText.toLowerCase())
                      )
                      .map((item, idx) => (
                        <div
                          key={idx}
                          className="box"
                          style={{
                            marginTop: "40px",
                            marginTop: "4rem",
                          }}
                        >
                          <div
                            style={{
                              padding: "20px",
                              position: "relative",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: "20px 0px",
                              zIndex: "1",
                            }}
                          >
                            <img
                              src="/images/admin/01-home/server.svg"
                              className="bg-image"
                              style={{
                                position: "absolute",
                                width: "100%",
                                height: "100%",
                                left: "0",
                                right: "0",
                                top: "0",
                                zIndex: "-1",
                              }}
                            />
                            {/* VMC- Button */}
                            {/* <button
                        style={{
                          position: "relative",
                          left: "100px",
                          top: "20px",
                          border: "none",
                          backgroundColor: "transparent",
                        }}
                        onClick={() => VMC(item.vm_id)}
                      >
                        <img
                          src={"./vmc-monitor.png"}
                          alt={"./vmc-monitor.png"}
                          style={{ width: "25px", height: "25px" }}
                        />
                      </button> */}
                            {item.vm_id === deletingMachine ? (
                              <div>
                                <div
                                  class="loader-delete"
                                  style={{
                                    position: "relative",
                                    left: "95px",
                                    top: "12px",
                                    border: "none",
                                  }}
                                ></div>
                              </div>
                            ) : item.vm_id == null ? (
                              <>
                                <img
                                  src={"./i-icon.png"}
                                  alt={"./i-icon.png"}
                                  style={{
                                    width: "25px",
                                    height: "25px",
                                  }}
                                />
                              </>
                            ) : (
                              <>
                                {item.current_action_status == "PROCESSING" ? (
                                  <div
                                    class="loader-vnc"
                                    style={{
                                      position: "relative",
                                      height: "10px",
                                      width: "20px",
                                      left: "90px",
                                      // top: "20px",
                                      border: "none",
                                      backgroundColor: "transparent",
                                    }}
                                  ></div>
                                ) : item.current_action_status == "Failed" ? (
                                  <>
                                    {machineUser &&
                                    machineUser.force_status == 1 ? (
                                      <>
                                        <button
                                          style={{
                                            position: "relative",
                                            left: "100px",
                                            // top: "20px",
                                            border: "none",
                                            backgroundColor: "transparent",
                                          }}
                                          onClick={() => RebuildMachine(item)}
                                        >
                                          <img
                                            src={"./refresh.png"}
                                            alt={"./refresh.png"}
                                            style={{
                                              width: "25px",
                                              height: "25px",
                                            }}
                                          />
                                        </button>
                                      </>
                                    ) : (
                                      <>
                                        <img
                                          src={"./i-icon.png"}
                                          alt={"./i-icon.png"}
                                          style={{
                                            width: "25px",
                                            height: "25px",
                                          }}
                                        />
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <button
                                    style={{
                                      position: "relative",
                                      left: "100px",
                                      // top: "20px",
                                      border: "none",
                                      backgroundColor: "transparent",
                                    }}
                                    onClick={() => {
                                      if (item.vm_id !== null) {
                                        if (item.sla_status == 1) {
                                          VMC(item.vm_id);
                                        } else {
                                          !isProfileVerified
                                            ? navigate("/edit-profile")
                                            : slaManagement(item.vm_id);
                                          //setShowTermsCondition(true);
                                        }
                                      }
                                    }}
                                  >
                                    <img
                                      src={"./vmc-monitor.png"}
                                      alt={"./vmc-monitor.png"}
                                      style={{ width: "25px", height: "25px" }}
                                    />
                                  </button>
                                )}
                              </>
                            )}

                            <div
                              className="machine-icon cpu-icon"
                              style={{ marginTop: "-60px", marginLeft: "40px" }}
                            >
                              <div
                                className="in-border"
                                style={{
                                  marginLeft: "12px",
                                  alignContent: "center",
                                  height: "100px",
                                  width: "100px",
                                  borderColor: "yellow",
                                  border: "2px solid #E97730",
                                  borderRadius: "50%",
                                  backgroundColor: "transparent",
                                  padding: "0",
                                }}
                              >
                                <div
                                  className="in-border"
                                  style={{
                                    height: "80px",
                                    width: "80px",
                                    padding: "1px 1px 1px 12px",
                                    borderColor: "yellow",
                                    border: "2px solid #E97730",
                                    borderRadius: "50%",
                                    margin: "auto",
                                    backgroundColor: "#E97730",
                                  }}
                                >
                                  <figure
                                    style={{
                                      background: "#e9773000",
                                      borderRadius: "50%",
                                      padding: "20px 8px 0px 0px",
                                      // width: "70px",
                                      // height: "70px",
                                      objectFit: "cover",
                                      display: "table",
                                      margin: "auto",
                                    }}
                                  >
                                    {" "}
                                    {item.vm_status === 0 && !shownOnce ? (
                                      <div
                                        class="loader-machine-make"
                                        style={{
                                          width: "77px",
                                          marginTop: "-22px",
                                          marginLeft: "-12px",
                                        }}
                                      ></div>
                                    ) : (
                                      <>
                                        <img
                                          src={"/images/admin/01-home/cpu.svg"}
                                          alt={""}
                                          style={{
                                            width: "40px",
                                            height: "40px",
                                          }}
                                        />
                                      </>
                                    )}
                                  </figure>
                                </div>
                              </div>
                              <Button
                                style={{
                                  marginTop: "-2px",
                                  width: "110px",
                                  height: "35px",
                                  marginLeft: "10px",
                                  backgroundColor: "transparent",
                                  border: "3px solid rgb(233, 119, 48)",
                                  borderRadius: "25px",
                                }}
                              >
                                <h4
                                  style={{
                                    color: "white",
                                    backgroundColor: "rgb(233, 119, 48)",
                                    paddingTop: "4px",
                                    borderRadius: "15px",
                                    height: "25px",
                                    marginTop: "-4px",
                                    fontSize: "14px",
                                    fontWeight: "700",
                                    marginLeft: "-10px",
                                    width: "100px",
                                  }}
                                >
                                  <>
                                    {item.vm_status === 0 && !shownOnce ? (
                                      <>Loading..</>
                                    ) : (
                                      <>
                                        {item.vm_config_category === 1 ||
                                        item.vm_config_category === 5 ||
                                        item.vm_config_category === 6
                                          ? "Standard"
                                          : item.vm_config_category === 3 ||
                                            item.vm_config_category === 7 ||
                                            item.vm_config_category === 8
                                          ? "CPU Intensive"
                                          : item.vm_config_category === 4 ||
                                            item.vm_config_category === 9 ||
                                            item.vm_config_category === 10
                                          ? "RAM Intensive"
                                          : ""}
                                      </>
                                    )}
                                  </>
                                </h4>
                              </Button>
                              <div className="front-mobile" key={index}>
                                <div
                                  style={{
                                    zIndex: "999",
                                    left: "-90px",
                                    top: "-18px",
                                    position: "relative",
                                    height: "4px",
                                    width: "15px",
                                    backgroundColor: "rgb(255 255 255)",
                                  }}
                                ></div>
                              </div>
                              {/* <img
                              src="/images/admin/01-home/cpu.svg"
                              className="cpu"
                            /> */}
                            </div>

                            {/* <div className="machine-title">CPU Intensive</div> */}
                            <div
                              className="machine-name theme-color-blue"
                              style={{
                                marginTop: "-15px",
                              }}
                            >
                              {" "}
                              {item.vm_name}
                            </div>
                            <div
                              className="machine-config-badge"
                              style={{
                                color: "white",
                              }}
                            >
                              {item.ram / 1024} GB / {item.hard_disk} GB /{" "}
                              {item.cpu} vCPU(s)
                            </div>
                            <div
                              className="line-shape"
                              style={{
                                width: "95%",
                                height: "2px",
                                borderRadius: "50%",
                                marginTop: "10px",
                                background:
                                  "linear-gradient(90deg, rgba(190, 190, 190, 0), rgba(190, 190, 190, 1), rgba(190, 190, 190, 0))",
                              }}
                            ></div>
                            {/* <div className="machine-ip" style={{ marginTop: "8px" }}>
                        Public IP: {item.public_ip}
                      </div> */}
                            {item.location !== null ? (
                              <div
                                className="machine-ip"
                                style={{ marginTop: "8px" }}
                              >
                                Location: {item.location}
                              </div>
                            ) : (
                              <div
                                className="machine-ip"
                                style={{
                                  marginTop: "10px",
                                  display: "flex",
                                  marginBottom: "-10px",
                                }}
                              >
                                <a style={{ marginLeft: "-30%" }}>Location:</a>
                                <p
                                  className="loader-ip"
                                  style={{
                                    height: "12px",
                                    marginTop: "1px",
                                    marginLeft: "5px",
                                  }}
                                ></p>
                              </div>
                            )}
                            {item.public_ip !== null ? (
                              <div
                                className="machine-ip"
                                style={{ marginTop: "8px" }}
                              >
                                Public IP: {item.public_ip}
                              </div>
                            ) : (
                              <div
                                className="machine-ip"
                                style={{
                                  marginTop: "8px",
                                  display: "flex",
                                  marginBottom: "-15px",
                                }}
                              >
                                <a style={{ marginLeft: "-30%" }}>Public IP:</a>
                                <p
                                  className="loader-ip"
                                  style={{
                                    marginTop: "1px",
                                    marginLeft: "5px",
                                  }}
                                ></p>
                              </div>
                            )}
                            {item.ip_address !== null ? (
                              <div
                                className="machine-ip"
                                style={{ marginTop: "8px" }}
                              >
                                Private IP: {item.ip_address}
                              </div>
                            ) : (
                              <div
                                className="machine-ip"
                                style={{
                                  marginTop: "8px",
                                  display: "flex",
                                  marginBottom: "-20px",
                                }}
                              >
                                <a style={{ marginLeft: "-30%" }}>
                                  Private IP:
                                </a>
                                <p
                                  className="loader-ip"
                                  style={{
                                    marginTop: "1px",
                                    marginLeft: "5px",
                                  }}
                                ></p>
                              </div>
                            )}
                            {/* <div className="machine-ip" style={{ marginTop: "8px" }}>
                        Private IP: {item.ip_address}
                      </div> */}
                            {/* <p className="loader"></p> */}
                            <div
                              className="machine-date"
                              style={{ marginTop: "8px" }}
                            >
                              Created:{item.created_at}
                            </div>
                            <div
                              className="actions"
                              style={{
                                paddingLeft: "20px",
                                marginTop: "-4px",
                                marginBottom: "10px",
                                display: "flex",
                                justifyContent: "space-around",
                              }}
                            >
                              <div
                                className="log-in"
                                style={{
                                  marginBottom: "6px",
                                }}
                                onClick={() => {
                                  if (item.vm_id !== null) {
                                    if (item.sla_status == 1) {
                                      if (
                                        item.current_action_status !==
                                        "PROCESSING"
                                      ) {
                                        navigate("/vm/status", {
                                          state: { vmDetails: item.vm_id },
                                        });
                                      } else {
                                        toast((t) => (
                                          <AppToast
                                            id={t.id}
                                            message={`${"Server configuration in Progress."}\n${"Please Return after 15 Minutes from the Creation Time..."}`}
                                            isMobile={isMobile}
                                          />
                                        ));
                                      }
                                    } else {
                                      //setShowTermsCondition(true);
                                      !isProfileVerified
                                        ? navigate("/edit-profile")
                                        : slaManagement(item.vm_id);
                                    }
                                  }
                                }}
                              >
                                <a className="media-link">
                                  <div
                                    className="media-banner"
                                    style={{
                                      minWidth: "6rem",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <img
                                      className="normal-banner"
                                      src="/admin/images/admin/wallet/add-money-btn.png"
                                      alt=""
                                      style={{
                                        width: "6rem",
                                      }}
                                    />
                                    <img
                                      className="hover-img-banner"
                                      src="/admin/images/admin/wallet/recharge-btn.png"
                                      alt="/admin/images/admin/wallet/recharge-btn.png"
                                    />
                                    <span
                                      className="login-text"
                                      style={{
                                        color: "white",
                                        fontSize: "15px",
                                        marginTop: "0px",
                                        fontWeight: "600",
                                      }}
                                    >
                                      View Status
                                    </span>
                                  </div>
                                </a>
                              </div>

                              <div
                                className="log-in"
                                style={{
                                  marginBottom: "6px",
                                }}
                                onClick={() => {
                                  if (item.vm_id !== null) {
                                    if (item.sla_status == 1) {
                                      if (
                                        item.current_action_status !==
                                          "PROCESSING" &&
                                        item.current_action_status !== "Failed"
                                      ) {
                                        navigate("/vm/moniter/utilize", {
                                          state: { vm_data: item.vm_id },
                                        });
                                      } else {
                                        toast((t) => (
                                          <AppToast
                                            id={t.id}
                                            message={`${"Server configuration in Progress."}\n${"Please Return after 15 Minutes from the Creation Time..."}`}
                                            isMobile={isMobile}
                                          />
                                        ));
                                      }
                                    } else {
                                      //setShowTermsCondition(true);
                                      !isProfileVerified
                                        ? navigate("/edit-profile")
                                        : slaManagement(item.vm_id);
                                    }
                                  }
                                }}
                              >
                                <a className="media-link">
                                  <div
                                    className="media-banner"
                                    style={{
                                      minWidth: "6rem",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <img
                                      className="normal-banner"
                                      src="/admin/images/admin/wallet/add-money-btn.png"
                                      alt=""
                                      style={{
                                        width: "6rem",
                                      }}
                                    />
                                    <img
                                      className="hover-img-banner"
                                      src="/admin/images/admin/wallet/recharge-btn.png"
                                      alt="/admin/images/admin/wallet/recharge-btn.png"
                                    />
                                    <span
                                      className="login-text"
                                      style={{
                                        color: "white",
                                        fontSize: "15px",
                                        marginTop: "0px",
                                        fontWeight: "600",
                                      }}
                                    >
                                      Monitor
                                    </span>
                                  </div>
                                </a>
                              </div>
                            </div>

                            <div
                              className="actions"
                              style={{ marginTop: "-1px" }}
                            ></div>
                            {/* <div className="checkstyle button b2" id="button-11">
                        <a onclick="getON(this.id);" id="59">
                          <input type="checkbox" className="checkbox" />
                          <div className="knobs">
                            <span>ON</span>
                          </div>
                          <div className="layer"></div>
                        </a>
                      </div> */}
                            <div className="icons log-in">
                              {/* <div
                        className={`switch ${isOn ? "on" : "off"}`}
                        onClick={() =>
                          isOn
                            ? toggleSwitch({
                                id: "item.vm_id",
                                status: "off",
                              })
                            : toggleSwitch({
                                id: "item.vm_id",
                                status: "on",
                              })
                        }
                      >
                        <div className="toggle"></div>
                        <div className="labels">
                          <span>ON</span>
                          <span>OFF</span>
                        </div> */}
                              <div
                                onClick={() => {
                                  if (item.vm_id !== null) {
                                    if (item.sla_status == 1) {
                                      if (
                                        item.current_action_status !==
                                        "PROCESSING"
                                      ) {
                                        setEditMachineId(item.vm_id);
                                        SetEditPopup(true);
                                      } else {
                                        toast((t) => (
                                          <AppToast
                                            id={t.id}
                                            message={`${"Server configuration in Progress."}\n${"Please Return after 15 Minutes from the Creation Time..."}`}
                                            isMobile={isMobile}
                                          />
                                        ));
                                      }
                                    } else {
                                      //setShowTermsCondition(true);
                                      !isProfileVerified
                                        ? navigate("/edit-profile")
                                        : slaManagement(item.vm_id);
                                    }
                                  }
                                }}
                              >
                                <a
                                  // href="https://smartcloudvm.com/vm/edit?id=59"
                                  className=""
                                  style={{
                                    marginLeft: "55px",
                                    marginBottom: "-15px",
                                    marginRight: "15px",
                                  }}
                                >
                                  <img src="/images/admin/01-home/edit.svg" />
                                </a>
                              </div>
                              <div
                                onClick={() => {
                                  if (item.vm_id !== null) {
                                    if (item.sla_status == 1) {
                                      if (
                                        item.current_action_status !==
                                        "PROCESSING"
                                      ) {
                                        SetDeletePopup(true);
                                        setMachineToDelete({
                                          vm_id: item.vm_id,
                                          id: item.id,
                                        });
                                        // setDeleteById(item.id);
                                        // deleteMachine(item.vm_id);
                                      } else {
                                        toast((t) => (
                                          <AppToast
                                            id={t.id}
                                            message={`${"Server configuration in Progress."}\n${"Please Return after 15 Minutes from the Creation Time..."}`}
                                            isMobile={isMobile}
                                          />
                                        ));
                                      }
                                    } else {
                                      //setShowTermsCondition(true);
                                      !isProfileVerified
                                        ? navigate("/edit-profile")
                                        : slaManagement(item.vm_id);
                                    }
                                  }
                                }}
                              >
                                <a
                                  // href="#"
                                  className="delete"
                                  id="59"
                                  onclick="getDistroy(this.id);"
                                  style={{
                                    marginBottom: "-5px",
                                    marginRight: "-30px",
                                  }}
                                >
                                  <img src="/images/admin/01-home/delete.svg" />
                                </a>
                              </div>
                            </div>
                            <div
                              className={`switch ${
                                item.status == 1 ? "on" : "off"
                              }`}
                              onClick={() => {
                                if (item.vm_id !== null) {
                                  if (item.sla_status == 1) {
                                    if (
                                      item.current_action_status !==
                                        "PROCESSING" &&
                                      item.current_action_status !== "Failed"
                                    ) {
                                      item.status == 1
                                        ? toggleSwitch({
                                            id: item.vm_id,
                                            status: "off",
                                          })
                                        : toggleSwitch({
                                            id: item.vm_id,
                                            status: "on",
                                          });
                                    } else {
                                      toast((t) => (
                                        <AppToast
                                          id={t.id}
                                          message={`${"Server configuration in Progress."}\n${"Please Return after 15 Minutes from the Creation Time..."}`}
                                          isMobile={isMobile}
                                        />
                                      ));
                                    }
                                  } else {
                                    //setShowTermsCondition(true);
                                    !isProfileVerified
                                      ? navigate("/edit-profile")
                                      : slaManagement(item.vm_id);
                                  }
                                }
                              }}
                              style={{
                                marginTop: "-25px",
                                marginLeft: "-110px",
                              }}
                            >
                              <div className="toggle"></div>
                              <div className="labels">
                                <span>ON</span>
                                <span>OFF</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                </div>
              </div>
            </>
          ) : (
            <>
              {showPlaceholder && (
                <div style={{ position: "absolute", top: "30%", left: "30%" }}>
                  <figure
                    style={{
                      width: "100px",
                      height: "100px",
                      background: "rgb(233, 119, 48)",
                      borderRadius: "50%",

                      objectFit: "cover",

                      margin: "auto",
                      padding: "20px",
                      outline: "3px solid rgb(233, 119, 48)",
                      border: "5px solid white",
                    }}
                  >
                    <img
                      src={"/admin/images/admin/menu/vm-white.png"}
                      alt={""}
                      style={{
                        marginLeft: "-5px",
                        width: "60px",
                        height: "60px",
                      }}
                    />
                  </figure>
                  <div
                    style={{
                      zIndex: "10",
                      top: "95px",
                      left: "70px",
                      position: "absolute",
                      width: "20px",
                      height: "7px",
                      backgroundColor: "#fff",
                    }}
                  ></div>
                  <button
                    className="create-new-machine"
                    onClick={() => navigate("/vm/create")}
                  >
                    Create Machine
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      ) : (
        // WEBVIEW

        <div
          className="features-page-solution"
          style={{ height: "100%", padding: "5rem" }}
        >
          {vncPopup && (
            <div
              style={{
                content: "",
                top: "0",
                left: "0",
                right: "0",
                position: "fixed",
                width: "100%",
                height: "100vh",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                zIndex: "9999999",
              }}
            >
              <div
                style={{
                  position: "relative",
                }}
              >
                <div>
                  <img
                    src="/admin/images/admin/transaction-failed/transaction-failed-bg.png"
                    style={{
                      width: "25rem",
                      height: "15rem",
                      position: "absolute",
                      marginTop: "20%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  />
                  {/* inner box */}
                  <div
                    style={{
                      position: "relative",
                      zIndex: "1",
                      padding: "2.5rem 1.5rem",
                    }}
                  >
                    {/* Close Button */}
                    <div onClick={() => SetVncPopup(false)}>
                      {" "}
                      <FaX
                        style={{
                          position: "absolute",
                          top: "54%",
                          right: "39%",
                          color: "#fff",
                          display: "inline-block",
                          fontSize: "1.5rem",
                        }}
                      />
                    </div>
                    {/* header */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {vNCinfo && (
                        <div
                          style={{
                            position: "relative",
                            marginLeft: "45%",
                            marginTop: "15%",
                          }}
                        >
                          <span
                            style={{
                              color: "#154e7a",
                              fontSize: "24px",
                              fontWeight: "600",
                            }}
                          >
                            PORT : {vNCinfo.port} <br />
                            IP : {vNCinfo.ip}
                            <br />
                            PASSWORD : {vNCinfo.password}
                            <br />
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="heading-dotted-machine">
            VM List <span></span>
          </div>

          {machineData && machineData.length > 0 && (
            <div
              className="input-container"
              style={{
                marginLeft: "85%",
                position: "relative",
                border: "2px solid #035189",
                width: "15rem",
                marginTop: "-72px",
                height: "55px",
              }}
            >
              <input
                type="text"
                name="search"
                className="input-signup input-tickets"
                placeholder="Search Machine"
                value={searchText}
                style={{
                  fontSize: "24px",
                  color: "black",
                  textAlign: "center",
                  width: "10px",
                }}
                onChange={(e) => setSearchText(e.target.value)}
              />
              {searchText && (
                <button
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                  }}
                  onClick={() => setSearchText("")}
                >
                  <FaX
                    style={{
                      marginBottom: "2px",
                      color: "#154e7a",
                      display: "inline-block",
                      fontSize: "19px",
                    }}
                  />
                </button>
              )}
            </div>
          )}

          <div className="features-section-solution">
            <Row>
              <div className="col-md-1"></div>
              <div className="col-md-10">
                {machineData && machineData.length > 0 ? (
                  <>
                    {editPopup && (
                      <div
                        style={{
                          top: "10%",
                          left: "25%",
                          position: "absolute",
                          zIndex: "9",
                          width: "70rem",
                          backdropFilter: "blur(5px)",
                          height: "40rem",
                          // backgroundColor: "orange",
                          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)", // Box shadow added
                          borderRadius: "12px", // Assuming you want rounded corners
                          border: "2px solid #e97730",
                        }}
                      >
                        <button
                          style={{
                            zIndex: "9",
                            position: "absolute",
                            backgroundColor: "transparent",
                            border: "none",
                            right: "0",
                          }}
                          onClick={() => SetEditPopup(!editPopup)}
                        >
                          <FaX
                            style={{
                              marginBottom: "2px",
                              color: "#e97730",
                              display: "inline-block",
                              fontSize: "19px",
                            }}
                          />
                        </button>{" "}
                        <div
                          style={{
                            position: "relative",
                            marginLeft: "25%",
                            marginTop: "23%",
                            display: "flex",
                            flexWrap: "nowrap",
                            flexDirection: "row",
                            gap: "20%",
                          }}
                        >
                          <div>
                            <figure
                              style={{
                                width: "100px",
                                height: "100px",
                                background: "rgb(233, 119, 48)",
                                borderRadius: "50%",

                                objectFit: "cover",

                                margin: "auto",
                                padding: "20px",
                                outline: "3px solid rgb(233, 119, 48)",
                                border: "5px solid white",
                              }}
                            >
                              <img
                                src={"/images/admin/01-home/cpu.svg"}
                                alt={""}
                                style={{
                                  marginLeft: "-5px",
                                  width: "60px",
                                  height: "60px",
                                }}
                              />
                            </figure>
                            <div
                              style={{
                                zIndex: "10",
                                top: "95px",
                                left: "85px",
                                position: "absolute",
                                width: "20px",
                                height: "7px",
                                backgroundColor: "#fff",
                              }}
                            ></div>
                            <button
                              style={{
                                width: "12rem",
                                marginTop: "2px",
                                zIndex: "9",
                                position: "relative",
                                // marginTop: "15%",
                                // left: "20%",
                                fontWeight: "700",
                                color: "white",
                                height: "55px",

                                // width: "10rem",
                                backgroundColor: "#e97730",
                                outline: "4px solid #e97730",
                                border: "4px solid #ffff",
                                borderColor: "white",
                                borderRadius: "30px",
                              }}
                              onClick={() =>
                                navigate("/edit-vm", {
                                  state: {
                                    machineData: editMachineId,
                                    editType: "os",
                                  },
                                })
                              }
                            >
                              Edit OS
                            </button>
                          </div>
                          <div>
                            {" "}
                            <figure
                              style={{
                                width: "100px",
                                height: "100px",
                                background: "rgb(233, 119, 48)",
                                borderRadius: "50%",
                                objectFit: "cover",
                                margin: "auto",
                                padding: "20px",
                                outline: "3px solid rgb(233, 119, 48)",
                                border: "5px solid white",
                              }}
                            >
                              <img
                                src={"/admin/images/admin/menu/vm-white.png"}
                                alt={""}
                                style={{
                                  marginLeft: "-5px",
                                  width: "60px",
                                  height: "60px",
                                }}
                              />
                            </figure>{" "}
                            <div
                              style={{
                                zIndex: "10",
                                top: "96px",
                                right: "23.3rem",
                                position: "absolute",
                                width: "20px",
                                height: "7px",
                                backgroundColor: "#ffff",
                              }}
                            ></div>
                            <button
                              style={{
                                width: "12rem",
                                marginTop: "2px",
                                zIndex: "9",
                                position: "relative",
                                // marginTop: "45%",
                                // left: "20%",
                                fontWeight: "700",
                                color: "white",
                                height: "55px",

                                // width: "10rem",
                                backgroundColor: "#e97730",
                                outline: "4px solid #e97730",
                                border: "4px solid #ffff",
                                borderColor: "white",
                                borderRadius: "30px",
                              }}
                              onClick={() =>
                                navigate("/edit-vm", {
                                  state: {
                                    machineData: editMachineId,
                                    editType: "config",
                                  },
                                })
                              }
                            >
                              Edit Config
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                    {deletePopup && (
                      <div
                        style={{
                          top: "7%",
                          left: "40%",
                          position: "absolute",
                          zIndex: "9",
                          width: "27rem",
                          backdropFilter: "blur(5px)",
                          height: "20rem",
                          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)", // Box shadow added
                          borderRadius: "12px", // Assuming you want rounded corners
                          border: "2px solid #e97730",
                        }}
                      >
                        <button
                          style={{
                            zIndex: "9",
                            position: "absolute",
                            backgroundColor: "transparent",
                            border: "none",
                            right: "0",
                          }}
                          onClick={() => SetDeletePopup(!deletePopup)}
                        >
                          <FaX
                            style={{
                              marginBottom: "2px",
                              color: "#e97730",
                              display: "inline-block",
                              fontSize: "19px",
                            }}
                          />
                        </button>{" "}
                        <h4
                          style={{
                            color: "#09528a",
                            textAlign: "center",
                            position: "absolute",
                            marginTop: "5rem",
                          }}
                        >
                          Do you Really Want to DELETE the Machine ?
                        </h4>
                        <div
                          style={{
                            position: "relative",
                            marginLeft: "10%",
                            marginTop: "45%",
                            display: "flex",
                            flexWrap: "nowrap",
                            flexDirection: "row",
                            gap: "3%",
                          }}
                        >
                          <div>
                            <button
                              style={{
                                width: "10rem",
                                marginTop: "2px",
                                zIndex: "9",
                                position: "relative",
                                fontWeight: "700",
                                color: "white",
                                height: "55px",
                                backgroundColor: "#aaa",
                                outline: "4px solid #aaa",
                                border: "4px solid #ffff",
                                borderColor: "white",
                                borderRadius: "30px",
                              }}
                              onClick={() => {
                                SetDeletePopup(false);
                                deleteMachine(machineToDelete);
                              }}
                            >
                              YES
                            </button>
                          </div>
                          <div>
                            {" "}
                            <button
                              style={{
                                width: "10rem",
                                marginTop: "2px",
                                zIndex: "9",
                                position: "relative",
                                fontWeight: "700",
                                color: "white",
                                height: "55px",
                                backgroundColor: "#e97730",
                                outline: "4px solid #e97730",
                                border: "4px solid #ffff",
                                borderColor: "white",
                                borderRadius: "30px",
                              }}
                              onClick={() => SetDeletePopup(!deletePopup)}
                            >
                              NO
                            </button>
                          </div>
                        </div>
                      </div>
                    )}

                    <div
                      className="wallet-container"
                      style={{
                        border: "none",
                        marginLeft: "30px",
                      }}
                    >
                      <div
                        className="wallet-box"
                        style={{
                          justifyContent: "unset",
                          alignContent: "flex-start",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          display: "flex",
                          gap: "20px",
                        }}
                      >
                        {machineData &&
                          filterByLocation(machineData, dropdownValue)
                            .filter((item) =>
                              item.vm_name
                                .toLowerCase()
                                .includes(searchText.toLowerCase())
                            )
                            // {machineData &&
                            //   machineData
                            //     .filter((item) =>
                            //       item.vm_name
                            //         .toLowerCase()
                            //         .includes(searchText.toLowerCase())
                            //     )
                            .map((item, idx) => (
                              <div
                                className="box"
                                key={idx}
                                style={{
                                  marginRight: "15px",
                                  marginTop: "15px",
                                }}
                              >
                                <div
                                  style={{
                                    padding: "20px",
                                    position: "relative",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    padding: "20px 0px",
                                    zIndex: "1",
                                  }}
                                >
                                  <img
                                    src="/images/admin/01-home/server.svg"
                                    className="bg-image"
                                    style={{
                                      position: "absolute",
                                      width: "100%",
                                      height: "105%",
                                      left: "0",
                                      right: "0",
                                      top: "0",
                                      zIndex: "-1",
                                    }}
                                  />
                                  {/* VMC- Button */}
                                  {/* item.current_action_status !== "Failed" */}
                                  {item.vm_id == deletingMachine ? (
                                    <div>
                                      <div
                                        class="loader-delete"
                                        style={{
                                          position: "relative",
                                          top: "25px",
                                          left: "90px",
                                          border: "none",
                                          // backgroundColor: "grey",
                                        }}
                                      ></div>
                                    </div>
                                  ) : item.vm_id === null ? (
                                    <>
                                      <img
                                        src={"./i-icon.png"}
                                        alt={"./i-icon.png"}
                                        style={{
                                          width: "25px",
                                          height: "25px",
                                        }}
                                      />
                                    </>
                                  ) : item.current_action_status ===
                                    "Failed" ? (
                                    <>
                                      <img
                                        src={"./i-icon.png"}
                                        alt={"./i-icon.png"}
                                        style={{
                                          width: "25px",
                                          height: "25px",
                                        }}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      {item.current_action_status ==
                                      "PROCESSING" ? (
                                        <div
                                          class="loader-vnc"
                                          style={{
                                            position: "relative",
                                            top: "40px",
                                            height: "15px",
                                            width: "30px",
                                            left: "90px",
                                            border: "none",
                                            backgroundColor: "grey",
                                          }}
                                        ></div>
                                      ) : item.current_action_status ==
                                        "Failed" ? (
                                        <>
                                          {machineUser &&
                                          machineUser.force_status == 1 ? (
                                            <>
                                              <button
                                                style={{
                                                  position: "relative",
                                                  left: "100px",
                                                  // top: "20px",
                                                  border: "none",
                                                  backgroundColor:
                                                    "transparent",
                                                }}
                                                onClick={() =>
                                                  RebuildMachine(item)
                                                }
                                              >
                                                <img
                                                  src={"./refresh.png"}
                                                  alt={"./refresh.png"}
                                                  style={{
                                                    width: "25px",
                                                    height: "25px",
                                                  }}
                                                />
                                              </button>
                                            </>
                                          ) : (
                                            <>
                                              <img
                                                src={"./i-icon.png"}
                                                alt={"./i-icon.png"}
                                                style={{
                                                  width: "25px",
                                                  height: "25px",
                                                }}
                                              />
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {/* {console.log(item.vnc_status, "VS")} */}
                                          {item.vnc_status &&
                                            item.vnc_status !== 0 && (
                                              <button
                                                style={{
                                                  position: "relative",
                                                  left: "100px",
                                                  top: "25px",
                                                  border: "none",
                                                  backgroundColor:
                                                    "transparent",
                                                }}
                                                onClick={() => {
                                                  if (item.vm_id !== null) {
                                                    if (item.sla_status == 1) {
                                                      VMC(item.vm_id);
                                                    } else {
                                                      !isProfileVerified
                                                        ? navigate(
                                                            "/edit-profile"
                                                          )
                                                        : slaManagement(
                                                            item.vm_id
                                                          );
                                                      //setShowTermsCondition(true);
                                                    }
                                                  }
                                                }}
                                              >
                                                <img
                                                  src={"./vmc-monitor.png"}
                                                  alt={"./vmc-monitor.png"}
                                                  style={{
                                                    width: "25px",
                                                    height: "25px",
                                                  }}
                                                />
                                              </button>
                                            )}
                                        </>
                                      )}
                                    </>
                                  )}

                                  <div>
                                    <div
                                      className="machine-icon cpu-icon"
                                      style={{ marginTop: "-50px" }}
                                    >
                                      <div
                                        className="in-border"
                                        style={{
                                          marginLeft: "12px",
                                          alignContent: "center",
                                          height: "100px",
                                          width: "100px",
                                          // padding: "5px",
                                          borderColor: "yellow",
                                          border: "2px solid #E97730",
                                          borderRadius: "50%",
                                          // display: "table",
                                          // margin: "auto",
                                          backgroundColor: "transparent",
                                          padding: "0",
                                        }}
                                      >
                                        <div
                                          className="in-border"
                                          style={{
                                            height: "80px",
                                            width: "80px",
                                            padding: "1px 1px 1px 12px",
                                            borderColor: "yellow",
                                            border: "2px solid #E97730",
                                            borderRadius: "50%",
                                            // display: "table",
                                            margin: "auto",
                                            backgroundColor: "#E97730",
                                          }}
                                        >
                                          <figure
                                            style={{
                                              background: "#e9773000",
                                              borderRadius: "50%",
                                              padding: "20px 8px 0px 0px",
                                              // width: "70px",
                                              // height: "70px",
                                              objectFit: "cover",
                                              display: "table",
                                              margin: "auto",
                                            }}
                                          >
                                            {item.vm_status === 0 &&
                                            item.current_action_status !==
                                              "Failed" &&
                                            !shownOnce ? (
                                              <div
                                                class="loader-machine-make"
                                                style={{
                                                  width: "80px",
                                                  marginTop: "-23px",
                                                  marginLeft: "-14px",
                                                }}
                                              ></div>
                                            ) : (
                                              // <h4
                                              //   style={{
                                              //     color: "white",
                                              //     marginTop: "3px",
                                              //   }}
                                              // >
                                              //   {seconds}
                                              // </h4>
                                              <>
                                                <img
                                                  src={
                                                    "/images/admin/01-home/cpu.svg"
                                                  }
                                                  alt={""}
                                                  style={{
                                                    width: "40px",
                                                    height: "40px",
                                                  }}
                                                />
                                              </>
                                            )}
                                          </figure>

                                          {/* <img
                                            src={"./i-icon.png"}
                                            alt={"./i-icon.png"}
                                            style={{
                                              width: "22px",
                                              height: "22px",
                                              marginLeft: "-92px",
                                              marginTop: "-5px",
                                              cursor: "pointer", // Change cursor on hover
                                              filter: isHovered
                                                ? "brightness(1.2)"
                                                : "none", // Apply effect on hover
                                              transform: isHovered
                                                ? "scale(1.1)"
                                                : "scale(1)", // Enlarge on hover
                                              transition:
                                                "transform 0.3s ease, filter 0.3s ease",
                                            }}
                                            onMouseEnter={() =>
                                              setIsHovered(true)
                                            } // Set hover to true
                                            onMouseLeave={() =>
                                              setIsHovered(false)
                                            }
                                          /> */}

                                          {isHovered && (
                                            <div
                                              className="machine-name theme-color-blue"
                                              style={{
                                                // width: "50px",
                                                marginLeft: "-92px",
                                                marginTop: "-45px",
                                                fontSize: "14px",
                                                fontWeight: "600",
                                              }}
                                            >
                                              {item.vm_type === 1
                                                ? "Cloud VM"
                                                : "RAW VM"}
                                            </div>
                                          )}
                                        </div>
                                      </div>

                                      <h4
                                        style={{
                                          borderColor: "white",
                                          border: "2px solid #fff",
                                          outline:
                                            "2px solid rgb(233, 119, 48)",
                                          textAlign: "center",
                                          color: "white",
                                          backgroundColor: "rgb(233, 119, 48)",
                                          padding: "2px 10px",
                                          borderRadius: "15px",
                                          height: "25px",
                                          // marginTop: "-4px",
                                          fontSize: "14px",
                                          fontWeight: "700",
                                          marginLeft:
                                            item.vm_config_category === 1 ||
                                            item.vm_config_category === 5 ||
                                            item.vm_config_category === 6
                                              ? "20px"
                                              : item.vm_config_category === 3 ||
                                                item.vm_config_category === 7 ||
                                                item.vm_config_category === 8
                                              ? "3px"
                                              : item.vm_config_category === 4 ||
                                                item.vm_config_category === 9 ||
                                                item.vm_config_category === 10
                                              ? "3px"
                                              : "",
                                          width: "max-content",
                                        }}
                                      >
                                        <>
                                          {item.vm_status === 0 &&
                                          item.current_action_status !==
                                            "Failed" &&
                                          !shownOnce ? (
                                            <>Loading..</>
                                          ) : (
                                            <>
                                              {item.vm_config_category === 1 ||
                                              item.vm_config_category === 5 ||
                                              item.vm_config_category === 6
                                                ? "Standard"
                                                : item.vm_config_category ===
                                                    3 ||
                                                  item.vm_config_category ===
                                                    7 ||
                                                  item.vm_config_category === 8
                                                ? "CPU Intensive"
                                                : item.vm_config_category ===
                                                    4 ||
                                                  item.vm_config_category ===
                                                    9 ||
                                                  item.vm_config_category === 10
                                                ? "RAM Intensive"
                                                : ""}
                                            </>
                                          )}
                                        </>
                                      </h4>
                                      {/* </Button> */}
                                      <div className="front-mobile" key={index}>
                                        <div
                                          style={{
                                            zIndex: "999",
                                            left: "56px",
                                            top: "-37px",
                                            position: "relative",
                                            height: "5px",
                                            width: "15px",
                                            backgroundColor: "#ffffff",
                                          }}
                                        ></div>
                                      </div>
                                      {/* <img
                                src="/images/admin/01-home/cpu.svg"
                                className="cpu"
                              /> */}
                                    </div>
                                  </div>

                                  {/* <div className="machine-title">CPU Intensive</div> */}
                                  <div className="machine-name theme-color-blue">
                                    {item.vm_name}
                                  </div>
                                  <div
                                    className="machine-config-badge"
                                    style={{
                                      color: "white",
                                    }}
                                  >
                                    {item.ram / 1024} GB /{" "}
                                    {item.disk_type == "hdd"
                                      ? item.hard_disk
                                      : item.disk_type == "ssd"
                                      ? item.ssd
                                      : item.nvme}{" "}
                                    GB / {item.cpu} vCPU(s)
                                  </div>
                                  <div
                                    className="line-shape"
                                    style={{
                                      width: "95%",
                                      height: "2px",
                                      borderRadius: "50%",
                                      marginTop: "10px",
                                      background:
                                        "linear-gradient(90deg, rgba(190, 190, 190, 0), rgba(190, 190, 190, 1), rgba(190, 190, 190, 0))",
                                    }}
                                  ></div>
                                  {/* <div
                                  className="machine-ip"
                                  style={{ marginTop: "8px" }}
                                >
                                  Public IP: {item.public_ip}
                                </div> */}
                                  {item.location !== null ? (
                                    <div
                                      className="machine-ip"
                                      style={{ marginTop: "8px" }}
                                    >
                                      Location: {item.location}
                                    </div>
                                  ) : (
                                    <div
                                      className="machine-ip"
                                      style={{
                                        marginTop: "10px",
                                        display: "flex",
                                        marginBottom: "-10px",
                                      }}
                                    >
                                      <a style={{ marginLeft: "-30%" }}>
                                        Location:
                                      </a>
                                      <p
                                        className="loader-ip"
                                        style={{
                                          height: "12px",
                                          marginTop: "1px",
                                          marginLeft: "5px",
                                        }}
                                      ></p>
                                    </div>
                                  )}
                                  {item.public_ip !== null ? (
                                    <div
                                      className="machine-ip"
                                      style={{ marginTop: "8px" }}
                                    >
                                      Public IP: {item.public_ip}
                                    </div>
                                  ) : (
                                    <div
                                      className="machine-ip"
                                      style={{
                                        marginTop: "10px",
                                        display: "flex",
                                        marginBottom: "-10px",
                                      }}
                                    >
                                      <a style={{ marginLeft: "-30%" }}>
                                        Public IP:
                                      </a>
                                      <p
                                        className="loader-ip"
                                        style={{
                                          height: "12px",
                                          marginTop: "1px",
                                          marginLeft: "5px",
                                        }}
                                      ></p>
                                    </div>
                                  )}
                                  {item.ip_address !== null ? (
                                    <div
                                      className="machine-ip"
                                      style={{ marginTop: "8px" }}
                                    >
                                      Private IP: {item.ip_address}
                                    </div>
                                  ) : (
                                    <div
                                      className="machine-ip"
                                      style={{
                                        marginTop: "10px",
                                        display: "flex",
                                        marginBottom: "-10px",
                                      }}
                                    >
                                      <a style={{ marginLeft: "-30%" }}>
                                        Private IP:
                                      </a>
                                      <p
                                        className="loader-ip"
                                        style={{
                                          height: "12px",
                                          marginTop: "1px",
                                          marginLeft: "5px",
                                        }}
                                      ></p>
                                    </div>
                                  )}

                                  <div
                                    className="machine-date"
                                    style={{ marginTop: "8px" }}
                                  >
                                    Created:{item.created_at}
                                    {/* {getTimeDifference(item.created_at)} */}
                                  </div>
                                  <div
                                    className="actions"
                                    style={{
                                      paddingLeft: "20px",
                                      marginTop: "-4px",
                                      display: "flex",
                                      justifyContent: "space-around",
                                    }}
                                  >
                                    <div
                                      className="log-in"
                                      style={{
                                        marginBottom: "6px",
                                        marginTop: "8px",
                                      }}
                                      onClick={() => {
                                        if (item.vm_id !== null) {
                                          if (item.sla_status == 1) {
                                            if (
                                              item.current_action_status !==
                                                "PROCESSING" &&
                                              item.current_action_status !==
                                                "Failed"
                                            ) {
                                              navigate("/vm/status", {
                                                state: {
                                                  vmDetails: item.vm_id,
                                                },
                                              });
                                            } else {
                                              toast((t) => (
                                                <AppToast
                                                  id={t.id}
                                                  message={`${"Server configuration in Progress."}\n${"Please Return after 15 Minutes from the Creation Time..."}`}
                                                  isMobile={isMobile}
                                                />
                                              ));
                                            }
                                          } else {
                                            //setShowTermsCondition(true);
                                            !isProfileVerified
                                              ? navigate("/edit-profile")
                                              : slaManagement(item.vm_id);
                                          }
                                        } else {
                                        }
                                      }}
                                    >
                                      <a className="media-link">
                                        <div
                                          className="media-banner"
                                          style={{
                                            minWidth: "6rem",
                                            marginTop: "5px",
                                          }}
                                        >
                                          <img
                                            className="normal-banner"
                                            src="/admin/images/admin/wallet/add-money-btn.png"
                                            alt=""
                                            style={{
                                              width: "6rem",
                                            }}
                                          />
                                          <img
                                            className="hover-img-banner"
                                            src={
                                              item.current_action_status !==
                                              "PROCESSING"
                                                ? "/admin/images/admin/wallet/recharge-btn.png"
                                                : "/admin/images/admin/wallet/add-money-btn.png"
                                            }
                                            alt={
                                              item.current_action_status !==
                                              "PROCESSING"
                                                ? "/admin/images/admin/wallet/recharge-btn.png"
                                                : "/admin/images/admin/wallet/add-money-btn.png"
                                            }
                                          />
                                          <span
                                            className="login-text"
                                            style={{
                                              color: "white",
                                              fontSize: "14px",
                                              marginTop: "0px",
                                              fontWeight: "600",
                                            }}
                                            onMouseOver={(e) =>
                                              item.current_action_status !==
                                                "PROCESSING" &&
                                              (e.target.style.color = "#07528B")
                                            } // Change color on hover if not disabled
                                            onMouseOut={(e) =>
                                              item.current_action_status !==
                                                "PROCESSING" &&
                                              (e.target.style.color = "white")
                                            }
                                          >
                                            View Status
                                          </span>
                                        </div>
                                      </a>
                                    </div>
                                    <div
                                      className="log-in"
                                      style={{
                                        marginBottom: "6px",
                                      }}
                                    >
                                      <a className="media-link">
                                        <div
                                          className="media-banner"
                                          style={{
                                            minWidth: "6rem",
                                            marginTop: "10px",
                                          }}
                                          onClick={() => {
                                            if (item.vm_id !== null) {
                                              if (item.sla_status == 1) {
                                                if (
                                                  item.current_action_status !==
                                                    "PROCESSING" &&
                                                  item.current_action_status !==
                                                    "Failed"
                                                ) {
                                                  navigate(
                                                    "/vm/moniter/utilize",
                                                    {
                                                      state: {
                                                        vm_data: item.vm_id,
                                                      },
                                                    }
                                                  );
                                                } else {
                                                  toast((t) => (
                                                    <AppToast
                                                      id={t.id}
                                                      message={`${"Server configuration in Progress."}\n${"Please Return after 15 Minutes from the Creation Time..."}`}
                                                      isMobile={isMobile}
                                                    />
                                                  ));
                                                }
                                              } else {
                                                //setShowTermsCondition(true);
                                                !isProfileVerified
                                                  ? navigate("/edit-profile")
                                                  : slaManagement(item.vm_id);
                                              }
                                            }
                                          }}
                                        >
                                          <img
                                            className="normal-banner"
                                            src="/admin/images/admin/wallet/add-money-btn.png"
                                            alt=""
                                            style={{
                                              width: "6rem",
                                            }}
                                          />

                                          <img
                                            className="hover-img-banner"
                                            src={
                                              item.public_ip != null
                                                ? "/admin/images/admin/wallet/recharge-btn.png"
                                                : "/admin/images/admin/wallet/add-money-btn.png"
                                            }
                                            alt={
                                              item.public_ip != null
                                                ? "/admin/images/admin/wallet/recharge-btn.png"
                                                : "/admin/images/admin/wallet/add-money-btn.png"
                                            }
                                          />
                                          <span
                                            className="login-text"
                                            style={{
                                              color: "white",
                                              fontSize: "14px",
                                              marginTop: "0px",
                                              fontWeight: "600",
                                            }}
                                            onMouseOver={(e) =>
                                              item.public_ip != null &&
                                              (e.target.style.color = "#07528B")
                                            } // Change color on hover if not disabled
                                            onMouseOut={(e) =>
                                              item.public_ip != null &&
                                              (e.target.style.color = "white")
                                            }
                                          >
                                            Monitor
                                          </span>
                                        </div>
                                      </a>
                                    </div>
                                  </div>

                                  <div
                                    className="actions"
                                    style={{ marginTop: "-1px" }}
                                  >
                                    {/* </div> */}
                                    <div
                                      className="icons log-in"
                                      style={{ gap: "15px" }}
                                    >
                                      <div
                                        className={`switch ${
                                          item.status == 1 ? "on" : "off"
                                        }`}
                                        onClick={() => {
                                          if (item.vm_id !== null) {
                                            if (item.sla_status == 1) {
                                              if (
                                                item.current_action_status !==
                                                  "PROCESSING" &&
                                                item.current_action_status !==
                                                  "Failed"
                                              ) {
                                                item.status == 1
                                                  ? toggleSwitch({
                                                      id: item.vm_id,
                                                      status: "off",
                                                    })
                                                  : toggleSwitch({
                                                      id: item.vm_id,
                                                      status: "on",
                                                    });
                                              } else {
                                                toast((t) => (
                                                  <AppToast
                                                    id={t.id}
                                                    message={`${"Server configuration in Progress."}\n${"Please Return after 15 Minutes from the Creation Time..."}`}
                                                    isMobile={isMobile}
                                                  />
                                                ));
                                              }
                                            } else {
                                              //setShowTermsCondition(true);
                                              !isProfileVerified
                                                ? navigate("/edit-profile")
                                                : slaManagement(item.vm_id);
                                            }
                                          }
                                        }}
                                      >
                                        <div className="toggle"></div>
                                        <div className="labels">
                                          <span>ON</span>
                                          <span>OFF</span>
                                        </div>
                                      </div>
                                      {isLoginByParentUser == 1 && (
                                        <button
                                          onClick={() => {
                                            if (item.vm_id !== null) {
                                              if (item.sla_status == 1) {
                                                if (
                                                  item.current_action_status !==
                                                  "PROCESSING"
                                                  //   &&
                                                  // item.current_action_status !==
                                                  //   "Failed"
                                                ) {
                                                  setEditMachineId(item.vm_id);
                                                  SetEditPopup(true);
                                                } else {
                                                  toast((t) => (
                                                    <AppToast
                                                      id={t.id}
                                                      message={`${"Server configuration in Progress."}\n${"Please Return after 15 Minutes from the Creation Time..."}`}
                                                      isMobile={isMobile}
                                                    />
                                                  ));
                                                }
                                              } else {
                                                //setShowTermsCondition(true);
                                                !isProfileVerified
                                                  ? navigate("/edit-profile")
                                                  : slaManagement(item.vm_id);
                                              }
                                            }
                                          }}
                                          disabled={item.public_ip == null}
                                          style={{
                                            border: "none",
                                            backgroundColor: "transparent",
                                          }}
                                        >
                                          <a
                                            // href="https://smartcloudvm.com/vm/edit?id=59"
                                            className=""
                                          >
                                            <img src="/images/admin/01-home/edit.svg" />
                                          </a>
                                        </button>
                                      )}

                                      {isLoginByParentUser == 1 && (
                                        <button
                                          onClick={() => {
                                            if (item.vm_id !== null) {
                                              if (item.sla_status == 1) {
                                                if (
                                                  item.current_action_status !==
                                                  "PROCESSING"
                                                ) {
                                                  SetDeletePopup(true);
                                                  setMachineToDelete({
                                                    vm_id: item.vm_id,
                                                    id: item.id,
                                                  });
                                                } else {
                                                  toast((t) => (
                                                    <AppToast
                                                      id={t.id}
                                                      message={`${"Server configuration in Progress."}\n${"Please Return after 15 Minutes from the Creation Time..."}`}
                                                      isMobile={isMobile}
                                                    />
                                                  ));
                                                }
                                              } else {
                                                //setShowTermsCondition(true);
                                                !isProfileVerified
                                                  ? navigate("/edit-profile")
                                                  : slaManagement(item.vm_id);
                                              }
                                            } else {
                                              if (
                                                item.current_action_status !==
                                                "PROCESSING"
                                                //    &&
                                                // item.current_action_status !==
                                                //   "Failed"
                                              ) {
                                                // setDeleteById(item.id);
                                                // deleteMachine({
                                                //   vm_id: item.vm_id,
                                                //   id: item.id,
                                                // });
                                                SetDeletePopup(true);
                                                setMachineToDelete({
                                                  vm_id: item.vm_id,
                                                  id: item.id,
                                                });
                                              } else {
                                                toast((t) => (
                                                  <AppToast
                                                    id={t.id}
                                                    message={`${"Server configuration in Progress."}\n${"Please Return after 15 Minutes from the Creation Time..."}`}
                                                    isMobile={isMobile}
                                                  />
                                                ));
                                              }
                                            }
                                          }}
                                          // disabled={item.public_ip == null}
                                          style={{
                                            border: "none",
                                            backgroundColor: "transparent",
                                          }}
                                        >
                                          <a
                                            //
                                            className="delete"
                                            // onclick="getDistroy(this.id);"
                                          >
                                            <img src="/images/admin/01-home/delete.svg" />
                                          </a>
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {isLoginByParentUser == 1 ? (
                      showPlaceholder && (
                        <div
                          style={{
                            position: "absolute",
                            top: "30%",
                            left: "50%",
                          }}
                        >
                          <figure
                            style={{
                              width: "100px",
                              height: "100px",
                              background: "rgb(233, 119, 48)",
                              borderRadius: "50%",

                              objectFit: "cover",

                              margin: "auto",
                              padding: "20px",
                              outline: "3px solid rgb(233, 119, 48)",
                              border: "5px solid white",
                            }}
                          >
                            <img
                              src={"/admin/images/admin/menu/vm-white.png"}
                              alt={""}
                              style={{
                                marginLeft: "-5px",
                                width: "60px",
                                height: "60px",
                              }}
                            />
                          </figure>
                          <div
                            style={{
                              zIndex: "10",
                              top: "95px",
                              left: "70px",
                              position: "absolute",
                              width: "20px",
                              height: "7px",
                              backgroundColor: "#fff",
                            }}
                          ></div>
                          <button
                            style={{
                              width: "10rem",
                              marginTop: "2px",
                              zIndex: "9",
                              position: "relative",
                              fontWeight: "700",
                              color: "white",
                              height: "55px",
                              backgroundColor: "#e97730",
                              outline: "4px solid #e97730",
                              border: "4px solid #ffff",
                              borderColor: "white",
                              borderRadius: "30px",
                            }}
                            onClick={() => navigate("/vm/create")}
                          >
                            Create Machine
                          </button>
                        </div>
                      )
                    ) : (
                      <div
                        style={{
                          position: "absolute",
                          top: "30%",
                          left: "47%",
                        }}
                      >
                        <figure
                          style={{
                            width: "100px",
                            height: "100px",
                            background: "rgb(233, 119, 48)",
                            borderRadius: "50%",

                            objectFit: "cover",

                            margin: "auto",
                            padding: "20px",
                            outline: "3px solid rgb(233, 119, 48)",
                            border: "5px solid white",
                          }}
                        >
                          <img
                            src={"/admin/images/admin/menu/vm-white.png"}
                            alt={""}
                            style={{
                              marginLeft: "-5px",
                              width: "60px",
                              height: "60px",
                            }}
                          />
                        </figure>
                        <div
                          style={{
                            zIndex: "10",
                            top: "95px",
                            left: "78px",
                            position: "absolute",
                            width: "20px",
                            height: "7px",
                            backgroundColor: "#fff",
                          }}
                        ></div>
                        <p
                          style={{
                            width: "11rem",
                            marginTop: "2px",
                            zIndex: "9",
                            position: "relative",
                            alignContent: "center",
                            textAlign: "center",
                            fontWeight: "700",
                            color: "white",
                            height: "55px",
                            backgroundColor: "#e97730",
                            outline: "4px solid #e97730",
                            border: "4px solid #ffff",
                            borderColor: "white",
                            borderRadius: "30px",
                          }}
                          // onClick={() => navigate("/vm/create")}
                        >
                          No Machines Found
                        </p>
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className="col-md-1"></div>
            </Row>
          </div>
        </div>
      )}
      <div className="apptoast-align">
        <Toaster
          position={isMobile ? "top-center" : "bottom-right"}
          reverseOrder={false}
        />
      </div>
    </div>
  );
};

export default MyMachinePage;
//web view
