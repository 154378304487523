import React, { useRef, useEffect, useState } from "react";
import { Container, Button, Carousel, Spinner, Row } from "react-bootstrap";
import "./ProductDetails.css";
import PageControl from "./PageControl";
import { useAuth } from "../AuthContext";
import instance, {
  apiDecrypteRequest,
  apiEncryptRequest,
  currencyReturn,
  decryptData,
} from "../Api";

const ProductDetailsUbantu = (props) => {
  const { isMobile } = props;
  const { appCurrency } = useAuth();
  const totalPages = 2;
  const scrollThreshold = 0.5; // Adjust as needed
  const [currentPage, setCurrentPage] = useState(1);
  const [index, setIndex] = useState(0);
  const [selectedBtn, setSelectedBtn] = useState(0);
  const handlePageChange = (page) => {
    setCurrentPage(page);
    window.scrollTo({
      top: (page - 1) * window.innerHeight,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [currentPage, totalPages]);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const pageHeight = window.innerHeight;
    const nextPage = Math.ceil(
      (scrollPosition + pageHeight * scrollThreshold) / pageHeight
    );

    console.log("Scroll Position:", scrollPosition);
    console.log("Page Height:", pageHeight);
    console.log("Next Page:", nextPage);

    if (nextPage >= 1 && nextPage <= totalPages && nextPage !== currentPage) {
      setCurrentPage(nextPage);
    }
  };
  const InnovativeSolutionsArr = [
    {
      title: "Latest CPU based compute",
      desc: "Overall best CPU based virtual machines, deploy in less than 30 seconds",
      img: "/images/cpu-white.png",
    },
    {
      title: "Well-founded Performance",
      desc: "Scale up your cloud server to add or reduce compute resources to meet changes in demand",
      img: "/images/performance-white.png",
    },
    {
      title: "Scalable",
      desc: "Scale up your cloud server to add or reduce compute resources to meet changes in demand.",
      img: "/images/scalable-white.png",
    },
    {
      title: "Bandwidth",
      desc: "SCVM enables you to do Huge amount of data transfer on 10Gig FC port within the each...",
      img: "/images/bandwidth-white.png",
    },
    {
      title: "Security",
      desc: "We offer verity of services to establish a secure access using WAF or gateway...",
      img: "/images/secure-01-white.png",
    },
  ];

  const standardArr = [
    {
      title: "Standard Linux Server",
      topPrice: `${currencyReturn({
        price: 10.81,
        symbol: localStorage.getItem("PrefCurrency"),
        rates: appCurrency,
      })} / Month`,
      price: `${currencyReturn({
        price: 0.48,
        symbol: localStorage.getItem("PrefCurrency"),
        rates: appCurrency,
      })} / day`,
      cpu: "1 CPU",
      ram: "40 GB RAM",
      hdd: "40 GB SSD Disk",
      data: "1 TB Data Transfer",
    },
    {
      title: "Standard Linux Server",
      topPrice: `${currencyReturn({
        price: 14.71,
        symbol: localStorage.getItem("PrefCurrency"),
        rates: appCurrency,
      })} / Month`,
      price: `${currencyReturn({
        price: 0.48,
        symbol: localStorage.getItem("PrefCurrency"),
        rates: appCurrency,
      })} / day`,
      cpu: "1 CPU",
      ram: "60 GB RAM",
      hdd: "60 GB SSD Disk",
      data: "2 TB Data Transfer",
    },
    {
      title: "Standard Linux Server",
      topPrice: `${currencyReturn({
        price: 21.62,
        symbol: localStorage.getItem("PrefCurrency"),
        rates: appCurrency,
      })} / Month`,
      price: `${currencyReturn({
        price: 0.72,
        symbol: localStorage.getItem("PrefCurrency"),
        rates: appCurrency,
      })} / day`,
      cpu: "2 CPU",
      ram: "80 GB RAM",
      hdd: "80 GB SSD Disk",
      data: "2 TB Data Transfer",
    },
    {
      title: "Standard Linux Server",
      topPrice: `${currencyReturn({
        price: 28.22,
        symbol: localStorage.getItem("PrefCurrency"),
        rates: appCurrency,
      })} / Month`,
      price: `${currencyReturn({
        price: 0.96,
        symbol: localStorage.getItem("PrefCurrency"),
        rates: appCurrency,
      })} / day`,
      cpu: "2 CPU",
      ram: "100 GB RAM",
      hdd: "100 GB SSD Disk",
      data: "3 TB Data Transfer",
    },
  ];
  const cpuArr = [
    {
      title: "Linux CPU Intensive VMs",
      topPrice: `${currencyReturn({
        price: 50.4,
        symbol: localStorage.getItem("PrefCurrency"),
        rates: appCurrency,
      })} / Month`,
      price: `${currencyReturn({
        price: 0.07,
        symbol: localStorage.getItem("PrefCurrency"),
        rates: appCurrency,
      })} / day`,
      cpu: "4 CPU",
      ram: "100 GB RAM",
      hdd: "100 GB SSD Disk",
      data: "4 TB Data Transfer",
    },
    {
      title: "Linux CPU Intensive VMs",
      topPrice: `${currencyReturn({
        price: 95.47,
        symbol: localStorage.getItem("PrefCurrency"),
        rates: appCurrency,
      })} / Month`,
      price: `${currencyReturn({
        price: 0.13,
        symbol: localStorage.getItem("PrefCurrency"),
        rates: appCurrency,
      })} / day`,
      cpu: "8 CPU",
      ram: "200 GB RAM",
      hdd: "200 GB SSD Disk",
      data: "8 TB Data Transfer",
    },
    {
      title: "Linux CPU Intensive VMs",
      topPrice: `${currencyReturn({
        price: 140.51,
        symbol: localStorage.getItem("PrefCurrency"),
        rates: appCurrency,
      })} / Month`,
      price: `${currencyReturn({
        price: 0.2,
        symbol: localStorage.getItem("PrefCurrency"),
        rates: appCurrency,
      })} / day`,
      cpu: "12 CPU",
      ram: "300 GB RAM",
      hdd: "300 GB SSD Disk",
      data: "10 TB Data Transfer",
    },
    {
      title: "Linux CPU Intensive VMs",
      topPrice: `${currencyReturn({
        price: 206.56,
        symbol: localStorage.getItem("PrefCurrency"),
        rates: appCurrency,
      })} / Month`,
      price: `${currencyReturn({
        price: 0.29,
        symbol: localStorage.getItem("PrefCurrency"),
        rates: appCurrency,
      })} / day`,
      cpu: "16 CPU",
      ram: "480 GB RAM",
      hdd: "480 GB SSD Disk",
      data: "15 TB Data Transfer",
    },
  ];

  const memoryArr = [
    {
      title: "Memory Intensive Server",
      topPrice: `${currencyReturn({
        price: 80.46,
        symbol: localStorage.getItem("PrefCurrency"),
        rates: appCurrency,
      })} / Month`,
      price: `${currencyReturn({
        price: 2.64,
        symbol: localStorage.getItem("PrefCurrency"),
        rates: appCurrency,
      })} / day`,
      cpu: "4 CPU",
      ram: "240 GB RAM",
      hdd: "240 GB SSD Disk",
      data: "4 TB Data Transfer",
    },
    {
      title: "Memory Intensive Server",
      topPrice: `${currencyReturn({
        price: 150.11,
        symbol: localStorage.getItem("PrefCurrency"),
        rates: appCurrency,
      })} / Month`,
      price: `${currencyReturn({
        price: 5.4,
        symbol: localStorage.getItem("PrefCurrency"),
        rates: appCurrency,
      })} / day`,
      cpu: "8 CPU",
      ram: "300 GB RAM",
      hdd: "300 GB SSD Disk",
      data: "8 TB Data Transfer",
    },
    {
      title: "Memory Intensive Server",
      topPrice: `${currencyReturn({
        price: 270.11,
        symbol: localStorage.getItem("PrefCurrency"),
        rates: appCurrency,
      })} / Month`,
      price: `${currencyReturn({
        price: 9.12,
        symbol: localStorage.getItem("PrefCurrency"),
        rates: appCurrency,
      })} / day`,
      cpu: "12 CPU",
      ram: "480 GB RAM",
      hdd: "480 GB SSD Disk",
      data: "10 TB Data Transfer",
    },
    {
      title: "Memory Intensive Server",
      topPrice: `${currencyReturn({
        price: 389.7,
        symbol: localStorage.getItem("PrefCurrency"),
        rates: appCurrency,
      })} / Month`,
      price: `${currencyReturn({
        price: 12.96,
        symbol: localStorage.getItem("PrefCurrency"),
        rates: appCurrency,
      })} / day`,
      cpu: "16 CPU",
      ram: "650 GB RAM",
      hdd: "650 GB SSD Disk",
      data: "15 TB Data Transfer",
    },
  ];
  const [activeTab, setActiveTab] = useState("#linux-services");

  const handleTabClick = (target) => {
    setActiveTab(target);
  };

  return (
    <div
      style={{
        width: "100%",
        minHeight: "100%",
        position: "relative",
        backgroundImage: isMobile ? `url(./main-bg.jpg)` : `url(./main-bg.jpg)`,
        backgroundSize: "cover",
        // backgroundPosition: "center",
        // backgroundColor: "#141414",
        backgroundRepeat: "round",
        backgroundBlendMode: "overlay",
      }}
    >
      {isMobile ? (
        <>
          <div
            style={{
              width: "100% !important",
              paddingBottom: "10px",
              justifyContent: "center",
            }}
          >
            <div
              className="tabs-pd"
              style={{ marginTop: "3rem", marginLeft: "0px" }}
            >
              <div
                className="heading-pd"
                style={{ fontSize: "20px", marginBottom: "5px" }}
              >
                {selectedBtn == 0
                  ? "Standard"
                  : selectedBtn == 1
                  ? "CPU Intensive"
                  : "Memory Intensive"}{" "}
                <span> Linux Servers</span>
              </div>
              <Button
                className="tablinks-mobile"
                style={{
                  color: selectedBtn === 0 ? "#ffd8bb" : "#154e7a",
                  backgroundColor: selectedBtn === 0 ? "#154e7a" : "#ffd8bb",
                }}
                onClick={() => setSelectedBtn(0)}
              >
                Standard
              </Button>
              <Button
                className="tablinks-mobile"
                style={{
                  color: selectedBtn === 1 ? "#ffd8bb" : "#154e7a",
                  backgroundColor: selectedBtn === 1 ? "#154e7a" : "#ffd8bb",
                }}
                onClick={() => setSelectedBtn(1)}
              >
                CPU intensive
              </Button>
              <Button
                className="tablinks-mobile"
                style={{
                  color: selectedBtn === 2 ? "#ffd8bb" : "#154e7a",
                  backgroundColor: selectedBtn === 2 ? "#154e7a" : "#ffd8bb",
                }}
                onClick={() => setSelectedBtn(2)}
              >
                {" "}
                Memory intensive
              </Button>
              <div className="" style={{ padding: "5px" }}>
                <p>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Laboriosam est ab ut ratione, similique dolores. Dignissimos
                  blanditiis placeat at ad!
                </p>
                <p>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Laudantium tempora quia dolor ipsa accusantium nisi, optio non
                  animi aliquid quod maxime ipsum earum! Laudantium explicabo
                  dolor ipsum voluptatum, similique est vel vero hic eius sequi?
                  Blanditiis quas veniam numquam at!
                </p>
              </div>
              <div className="package-container">
                <div
                  style={{
                    position: "relative",
                    color: "#154e7a",
                    fontSize: "40px",
                    textTransform: "capitalize",
                    fontWeight: "600",
                  }}
                >
                  Choose your
                </div>
                <div class="sub-heading">
                  high performance{" "}
                  {selectedBtn == 0
                    ? "Standard Servers"
                    : selectedBtn == 1
                    ? "CPU Intensive"
                    : "Memory Intensive"}
                </div>
                <div
                  className="wallet-box"
                  style={{
                    marginTop: "20px",
                    height: "40rem",
                    // width: "80%",
                    //marginLeft: "20rem",
                  }}
                >
                  {selectedBtn == 0 ? (
                    <>
                      {standardArr.map((item, idx) => (
                        <div className="" style={{ marginTop: "25px" }}>
                          <div className="package-top bg-gradient-white">
                            <div className="top-head-pd see-white-text">
                              <img
                                src="/images/title-bg-orange.png"
                                alt="Title Background Orange"
                              />
                              <img
                                className="hover-img"
                                src="/images/title-bg-white.png"
                                alt="Title Background White"
                              />
                              <span className="plan-typ">{item.title}</span>
                            </div>
                            <div className="top-body theme-color-blue">
                              <div className="mob-bg">
                                <div className="price-pd">{item.topPrice}</div>
                                <div className="or-bg">or</div>
                                <div className="time">{item.price}</div>
                              </div>
                            </div>
                          </div>
                          <div className="package-bottom">
                            <figure>
                              <img
                                src="/images/box-bg-white.png"
                                alt="Box Background White"
                              />
                              <img
                                className="hover-img"
                                src="/images/orange-box-bg.png"
                                alt="Orange Box Background"
                              />
                            </figure>
                            <div className="package-cont">
                              {/* <div className="title">Memory Intensive VMs Servers</div> */}
                              <div className="price-pd">{item.cpu}</div>
                              <div className="price-pd">{item.ram}</div>
                              <div className="price-pd">{item.hdd}</div>
                              <div className="price-pd">{item.data}</div>
                              <div className="price-pd">
                                <div
                                  className="log-in"
                                  style={{
                                    marginTop: "15px",
                                    marginLeft: "-8rem",
                                    justifyContent: "center",
                                  }}
                                  // onClick={() => UpdateInfo()}
                                >
                                  <a className="media-link" href="/signUp">
                                    <div
                                      className="media-banner"
                                      style={{
                                        width: "auto",
                                        height: "50px",

                                        marginLeft: "10rem",
                                      }}
                                    >
                                      <img
                                        className="normal-banner"
                                        src="/images/blue-btn-big.png"
                                        alt=""
                                        style={{
                                          marginTop: "-6px",
                                          width: "10rem",
                                          height: "50px",
                                        }}
                                      />
                                      <img
                                        className="hover-img-banner"
                                        src="/images/orange-btn-big.png"
                                        alt="/images/orange-btn-big.png"
                                        style={{
                                          marginTop: "-6px",
                                          width: "10rem",
                                          height: "50px",
                                        }}
                                      />
                                      <span
                                        className="login-text"
                                        style={{
                                          fontSize: "20px",
                                          color: "white",
                                          marginTop: "-8px",
                                        }}
                                      >
                                        Buy Now
                                      </span>
                                    </div>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : selectedBtn == 1 ? (
                    <>
                      {cpuArr.map((item, idx) => (
                        <div className="" style={{ marginTop: "10px" }}>
                          <div
                            className="package-top bg-gradient-white"
                            style={{ marginTop: "30px" }}
                          >
                            <div className="top-head-pd see-white-text">
                              <img
                                src="/images/title-bg-orange.png"
                                alt="Title Background Orange"
                              />
                              <img
                                className="hover-img"
                                src="/images/title-bg-white.png"
                                alt="Title Background White"
                              />
                              <span className="plan-typ">{item.title}</span>
                            </div>
                            <div className="top-body theme-color-blue">
                              <div className="mob-bg">
                                <div className="price-pd">{item.topPrice}</div>
                                <div className="or-bg">or</div>
                                <div className="time">{item.price}</div>
                              </div>
                            </div>
                          </div>
                          <div className="package-bottom">
                            <figure>
                              <img
                                src="/images/box-bg-white.png"
                                alt="Box Background White"
                              />
                              <img
                                className="hover-img"
                                src="/images/orange-box-bg.png"
                                alt="Orange Box Background"
                              />
                            </figure>
                            <div className="package-cont">
                              {/* <div className="title">Memory Intensive VMs Servers</div> */}
                              <div className="price-pd">{item.cpu}</div>
                              <div className="price-pd">{item.ram}</div>
                              <div className="price-pd">{item.hdd}</div>
                              <div className="price-pd">{item.data}</div>
                              <div className="price-pd">
                                <div
                                  className="log-in"
                                  style={{
                                    marginTop: "15px",
                                    marginLeft: "-8rem",
                                    justifyContent: "center",
                                  }}
                                  // onClick={() => UpdateInfo()}
                                >
                                  <a className="media-link" href="/signUp">
                                    <div
                                      className="media-banner"
                                      style={{
                                        width: "auto",
                                        height: "50px",

                                        marginLeft: "10rem",
                                      }}
                                    >
                                      <img
                                        className="normal-banner"
                                        src="/images/blue-btn-big.png"
                                        alt=""
                                        style={{
                                          marginTop: "-6px",
                                          width: "10rem",
                                          height: "50px",
                                        }}
                                      />
                                      <img
                                        className="hover-img-banner"
                                        src="/images/orange-btn-big.png"
                                        alt="/images/orange-btn-big.png"
                                        style={{
                                          marginTop: "-6px",
                                          width: "10rem",
                                          height: "50px",
                                        }}
                                      />
                                      <span
                                        className="login-text"
                                        style={{
                                          fontSize: "20px",
                                          color: "white",
                                          marginTop: "-8px",
                                        }}
                                      >
                                        Buy Now
                                      </span>
                                    </div>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      {memoryArr.map((item, idx) => (
                        <div className="" style={{ marginTop: "15px" }}>
                          <div className="package-top bg-gradient-white">
                            <div className="top-head-pd see-white-text">
                              <img
                                src="/images/title-bg-orange.png"
                                alt="Title Background Orange"
                              />
                              <img
                                className="hover-img"
                                src="/images/title-bg-white.png"
                                alt="Title Background White"
                              />
                              <span className="plan-typ">{item.title}</span>
                            </div>
                            <div className="top-body theme-color-blue">
                              <div className="mob-bg">
                                <div className="price-pd">{item.topPrice}</div>
                                <div className="or-bg">or</div>
                                <div className="time">{item.price}</div>
                              </div>
                            </div>
                          </div>
                          <div className="package-bottom">
                            <figure>
                              <img
                                src="/images/box-bg-white.png"
                                alt="Box Background White"
                              />
                              <img
                                className="hover-img"
                                src="/images/orange-box-bg.png"
                                alt="Orange Box Background"
                              />
                            </figure>
                            <div className="package-cont">
                              {/* <div className="title">Memory Intensive VMs Servers</div> */}
                              <div className="price-pd">{item.cpu}</div>
                              <div className="price-pd">{item.ram}</div>
                              <div className="price-pd">{item.hdd}</div>
                              <div className="price-pd">{item.data}</div>
                              <div className="price-pd">
                                <div
                                  className="log-in"
                                  style={{
                                    marginTop: "15px",
                                    marginLeft: "-8rem",
                                    justifyContent: "center",
                                  }}
                                  // onClick={() => UpdateInfo()}
                                >
                                  <a className="media-link" href="/signUp">
                                    <div
                                      className="media-banner"
                                      style={{
                                        width: "auto",
                                        height: "50px",

                                        marginLeft: "10rem",
                                      }}
                                    >
                                      <img
                                        className="normal-banner"
                                        src="/images/blue-btn-big.png"
                                        alt=""
                                        style={{
                                          marginTop: "-6px",
                                          width: "10rem",
                                          height: "50px",
                                        }}
                                      />
                                      <img
                                        className="hover-img-banner"
                                        src="/images/orange-btn-big.png"
                                        alt="/images/orange-btn-big.png"
                                        style={{
                                          marginTop: "-6px",
                                          width: "10rem",
                                          height: "50px",
                                        }}
                                      />
                                      <span
                                        className="login-text"
                                        style={{
                                          fontSize: "20px",
                                          color: "white",
                                          marginTop: "-8px",
                                        }}
                                      >
                                        Buy Now
                                      </span>
                                    </div>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </div>
                <div
                  style={{
                    marginTop: "5rem",
                  }}
                >
                  <div
                    className="heading-dotted-product"
                    style={{ fontSize: "30px" }}
                  >
                    Benefits Of Cloud <span></span>
                  </div>

                  <div
                    className="solution-posts-inner"
                    style={{
                      // marginLeft: "25rem",
                      marginTop: "5rem",
                      width: "100%",
                      marginBottom: "10rem",
                    }}
                  >
                    {InnovativeSolutionsArr.map((item, index) => (
                      <div className="solution-post">
                        <div
                          style={{
                            backgroundImage:
                              "linear-gradient(#FFFFFF, #EFEFEF)",
                            borderRadius: "15px",
                          }}
                        >
                          <div className="solution-card-solution" key={index}>
                            <div
                              className="in-border"
                              style={{
                                alignContent: "center",
                                height: "90px",
                                width: "90px",
                                // padding: "5px",
                                borderColor: "yellow",
                                border: "2px solid #E97730",
                                borderRadius: "50%",
                                // display: "table",
                                margin: "auto",
                                backgroundColor: "transparent",
                                marginTop: "-55%",
                                padding: "0",
                                position: "relative",
                                top: "2rem",
                              }}
                            >
                              <div
                                className="in-border"
                                style={{
                                  height: "80px",
                                  width: "80px",
                                  padding: "10px",
                                  borderColor: "yellow",
                                  border: "2px solid #E97730",
                                  borderRadius: "50%",
                                  // display: "table",
                                  margin: "auto",
                                  backgroundColor: "#E97730",
                                }}
                              >
                                <figure>
                                  <img src={item.img} alt={item.img} />
                                </figure>
                              </div>
                            </div>
                            <div className="content-solution">
                              <h3
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "500",
                                  color: "#2D394B",
                                  textAlign: "center",
                                  margin: "35px 0 10px",
                                }}
                              >
                                {item.title}
                              </h3>
                              <p className="description-solution-product">
                                Lorem ipsum dolor sit amet consectetur
                                adipiscing elit nulla elementum sit amet metus
                              </p>
                            </div>
                            <div
                              // className="log-in"
                              style={{ justifyContent: "center" }}
                            >
                              <a href="/blogmore" className="media-link">
                                <div
                                  className="media-banner"
                                  style={{ width: "auto" }}
                                >
                                  <img
                                    className="normal-banner"
                                    src="/images/read-more-btn.png"
                                    alt=""
                                  />
                                  <img
                                    className="hover-img-banner"
                                    src={"/images/orange-btn.png"}
                                    alt=""
                                  />
                                  <span
                                    className="login-text"
                                    style={{
                                      fontSize: "20px",
                                      color: "white",
                                      top: "46%",
                                    }}
                                    onMouseOver={(e) =>
                                      (e.target.style.color = "#07528B")
                                    } // Change color on hover
                                    onMouseOut={(e) =>
                                      (e.target.style.color = "white")
                                    }
                                  >
                                    Read More
                                  </span>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <Row>
            <div className="col-md-2"></div>

            <div
              className="product1-top see-full-pd col-md-10"
              style={{ marginTop: "5rem", marginLeft: "10rem" }}
            >
              <div className="product1-left see-pd-6">
                {" "}
                <div className="mob-center">
                  <div className="media-pd">
                    <img src="/images/Icons8_flat_linux.svg" alt="" />
                  </div>
                  <div className="heading-pd">
                    {selectedBtn == 0
                      ? "Standard"
                      : selectedBtn == 1
                      ? "CPU Intensive"
                      : "Memory Intensive"}{" "}
                    <span> Linux Servers</span>
                  </div>
                </div>
                {/* <div className="tabs-pd mobile-pd">
                  <Button className="tablinks">Standard</Button>
                  <Button className="tablinks">Standard</Button>
                  <Button className="tablinks">Standard</Button>
                </div> */}
              </div>
              <div className="product1-left see-pd-6">
                <p>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Laboriosam est ab ut ratione, similique dolores. Dignissimos
                  blanditiis placeat at ad!
                </p>
                <p>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Laudantium tempora quia dolor ipsa accusantium nisi, optio non
                  animi aliquid quod maxime ipsum earum! Laudantium explicabo
                  dolor ipsum voluptatum, similique est vel vero hic eius sequi?
                  Blanditiis quas veniam numquam at!
                </p>
              </div>
            </div>
            <div className="product1-bottom see-full-pd">
              <div className="tabs-pd">
                {/* #ffd8bb #154e7a */}
                <Button
                  className="tablinks"
                  style={{
                    color: selectedBtn === 0 ? "#ffd8bb" : "#154e7a",
                    backgroundColor: selectedBtn === 0 ? "#154e7a" : "#ffd8bb",
                  }}
                  onClick={() => setSelectedBtn(0)}
                >
                  Standard
                </Button>
                <Button
                  className="tablinks"
                  style={{
                    color: selectedBtn === 1 ? "#ffd8bb" : "#154e7a",
                    backgroundColor: selectedBtn === 1 ? "#154e7a" : "#ffd8bb",
                  }}
                  onClick={() => setSelectedBtn(1)}
                >
                  CPU intensive
                </Button>
                <Button
                  className="tablinks"
                  style={{
                    color: selectedBtn === 2 ? "#ffd8bb" : "#154e7a",
                    backgroundColor: selectedBtn === 2 ? "#154e7a" : "#ffd8bb",
                  }}
                  onClick={() => setSelectedBtn(2)}
                >
                  {" "}
                  Memory intensive
                </Button>
              </div>
            </div>
            <div className="package-container">
              <div
                style={{
                  position: "relative",
                  color: "#154e7a",
                  fontSize: "40px",
                  textTransform: "capitalize",
                  fontWeight: "600",
                }}
              >
                Choose your
              </div>
              <div class="sub-heading">
                high performance{" "}
                {selectedBtn == 0
                  ? "Standard Servers"
                  : selectedBtn == 1
                  ? "CPU Intensive"
                  : "Memory Intensive"}
              </div>
            </div>
            {/* <div className="wallet-container"> */}
            <div
              className="wallet-box"
              style={{
                marginTop: "80px",
                height: "20rem",
                width: "80%",
                marginLeft: "20rem",
              }}
            >
              {selectedBtn == 0 ? (
                <>
                  {standardArr.map((item, idx) => (
                    <div
                      className="package see-3 see-ltb-3 see-tb-3 see-sm-6 see-xsm-12"
                      style={{ paddingTop: "15px" }}
                    >
                      <div className="package-top bg-gradient-white">
                        <div className="top-head-pd see-white-text">
                          <img
                            src="/images/title-bg-orange.png"
                            alt="Title Background Orange"
                          />
                          <img
                            className="hover-img"
                            src="/images/title-bg-white.png"
                            alt="Title Background White"
                          />
                          <span className="plan-typ">{item.title}</span>
                        </div>
                        <div className="top-body theme-color-blue">
                          <div className="mob-bg">
                            <div className="price-pd">{item.topPrice}</div>
                            <div className="or-bg">or</div>
                            <div className="time">{item.price}</div>
                          </div>
                        </div>
                      </div>
                      <div className="package-bottom">
                        <figure>
                          <img
                            src="/images/box-bg-white.png"
                            alt="Box Background White"
                          />
                          <img
                            className="hover-img"
                            src="/images/orange-box-bg.png"
                            alt="Orange Box Background"
                          />
                        </figure>
                        <div className="package-cont">
                          {/* <div className="title">Memory Intensive VMs Servers</div> */}
                          <div className="price-pd">{item.cpu}</div>
                          <div className="price-pd">{item.ram}</div>
                          <div className="price-pd">{item.hdd}</div>
                          <div className="price-pd">{item.data}</div>
                          <div className="price-pd">
                            <div
                              className="log-in"
                              style={{
                                marginTop: "15px",
                                marginLeft: "-8rem",
                                justifyContent: "center",
                              }}
                              // onClick={() => UpdateInfo()}
                            >
                              <a className="media-link" href="/signUp">
                                <div
                                  className="media-banner"
                                  style={{
                                    width: "auto",
                                    height: "50px",

                                    marginLeft: "10rem",
                                  }}
                                >
                                  <img
                                    className="normal-banner"
                                    src="/images/blue-btn-big.png"
                                    alt=""
                                    style={{
                                      marginTop: "-6px",
                                      width: "10rem",
                                      height: "50px",
                                    }}
                                  />
                                  <img
                                    className="hover-img-banner"
                                    src="/images/orange-btn-big.png"
                                    alt="/images/orange-btn-big.png"
                                    style={{
                                      marginTop: "-6px",
                                      width: "10rem",
                                      height: "50px",
                                    }}
                                  />
                                  <span
                                    className="login-text"
                                    style={{
                                      fontSize: "20px",
                                      color: "white",
                                      marginTop: "-8px",
                                    }}
                                  >
                                    Buy Now
                                  </span>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : selectedBtn == 1 ? (
                <>
                  {cpuArr.map((item, idx) => (
                    <div
                      className="package see-3 see-ltb-3 see-tb-3 see-sm-6 see-xsm-12"
                      style={{ paddingTop: "15px" }}
                    >
                      <div className="package-top bg-gradient-white">
                        <div className="top-head-pd see-white-text">
                          <img
                            src="/images/title-bg-orange.png"
                            alt="Title Background Orange"
                          />
                          <img
                            className="hover-img"
                            src="/images/title-bg-white.png"
                            alt="Title Background White"
                          />
                          <span className="plan-typ">{item.title}</span>
                        </div>
                        <div className="top-body theme-color-blue">
                          <div className="mob-bg">
                            <div className="price-pd">{item.topPrice}</div>
                            <div className="or-bg">or</div>
                            <div className="time">{item.price}</div>
                          </div>
                        </div>
                      </div>
                      <div className="package-bottom">
                        <figure>
                          <img
                            src="/images/box-bg-white.png"
                            alt="Box Background White"
                          />
                          <img
                            className="hover-img"
                            src="/images/orange-box-bg.png"
                            alt="Orange Box Background"
                          />
                        </figure>
                        <div className="package-cont">
                          {/* <div className="title">Memory Intensive VMs Servers</div> */}
                          <div className="price-pd">{item.cpu}</div>
                          <div className="price-pd">{item.ram}</div>
                          <div className="price-pd">{item.hdd}</div>
                          <div className="price-pd">{item.data}</div>
                          <div className="price-pd">
                            <div
                              className="log-in"
                              style={{
                                marginTop: "15px",
                                marginLeft: "-8rem",
                                justifyContent: "center",
                              }}
                              // onClick={() => UpdateInfo()}
                            >
                              <a className="media-link" href="/signUp">
                                <div
                                  className="media-banner"
                                  style={{
                                    width: "auto",
                                    height: "50px",

                                    marginLeft: "10rem",
                                  }}
                                >
                                  <img
                                    className="normal-banner"
                                    src="/images/blue-btn-big.png"
                                    alt=""
                                    style={{
                                      marginTop: "-6px",
                                      width: "10rem",
                                      height: "50px",
                                    }}
                                  />
                                  <img
                                    className="hover-img-banner"
                                    src="/images/orange-btn-big.png"
                                    alt="/images/orange-btn-big.png"
                                    style={{
                                      marginTop: "-6px",
                                      width: "10rem",
                                      height: "50px",
                                    }}
                                  />
                                  <span
                                    className="login-text"
                                    style={{
                                      fontSize: "20px",
                                      color: "white",
                                      marginTop: "-8px",
                                    }}
                                  >
                                    Buy Now
                                  </span>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  {memoryArr.map((item, idx) => (
                    <div
                      className="package see-3 see-ltb-3 see-tb-3 see-sm-6 see-xsm-12"
                      style={{ paddingTop: "15px" }}
                    >
                      <div className="package-top bg-gradient-white">
                        <div className="top-head-pd see-white-text">
                          <img
                            src="/images/title-bg-orange.png"
                            alt="Title Background Orange"
                          />
                          <img
                            className="hover-img"
                            src="/images/title-bg-white.png"
                            alt="Title Background White"
                          />
                          <span className="plan-typ">{item.title}</span>
                        </div>
                        <div className="top-body theme-color-blue">
                          <div className="mob-bg">
                            <div className="price-pd">{item.topPrice}</div>
                            <div className="or-bg">or</div>
                            <div className="time">{item.price}</div>
                          </div>
                        </div>
                      </div>
                      <div className="package-bottom">
                        <figure>
                          <img
                            src="/images/box-bg-white.png"
                            alt="Box Background White"
                          />
                          <img
                            className="hover-img"
                            src="/images/orange-box-bg.png"
                            alt="Orange Box Background"
                          />
                        </figure>
                        <div className="package-cont">
                          {/* <div className="title">Memory Intensive VMs Servers</div> */}
                          <div className="price-pd">{item.cpu}</div>
                          <div className="price-pd">{item.ram}</div>
                          <div className="price-pd">{item.hdd}</div>
                          <div className="price-pd">{item.data}</div>
                          <div className="price-pd">
                            <div
                              className="log-in"
                              style={{
                                marginTop: "15px",
                                marginLeft: "-8rem",
                                justifyContent: "center",
                              }}
                              // onClick={() => UpdateInfo()}
                            >
                              <a className="media-link" href="/signUp">
                                <div
                                  className="media-banner"
                                  style={{
                                    width: "auto",
                                    height: "50px",

                                    marginLeft: "10rem",
                                  }}
                                >
                                  <img
                                    className="normal-banner"
                                    src="/images/blue-btn-big.png"
                                    alt=""
                                    style={{
                                      marginTop: "-6px",
                                      width: "10rem",
                                      height: "50px",
                                    }}
                                  />
                                  <img
                                    className="hover-img-banner"
                                    src="/images/orange-btn-big.png"
                                    alt="/images/orange-btn-big.png"
                                    style={{
                                      marginTop: "-6px",
                                      width: "10rem",
                                      height: "50px",
                                    }}
                                  />
                                  <span
                                    className="login-text"
                                    style={{
                                      fontSize: "20px",
                                      color: "white",
                                      marginTop: "-8px",
                                    }}
                                  >
                                    Buy Now
                                  </span>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
            {/* </div> */}
            <div
              style={{
                marginTop: "5rem",
              }}
            >
              <div
                className="heading-dotted-product"
                style={{ marginLeft: "15rem", fontSize: "50px" }}
              >
                Benefits Of Cloud <span></span>
              </div>

              <div
                className="solution-posts-inner"
                style={{
                  marginLeft: "25rem",
                  marginTop: "2rem",
                  width: "75%",
                  marginBottom: "10rem",
                }}
              >
                {InnovativeSolutionsArr.map((item, index) => (
                  <div className="solution-post">
                    <div
                      style={{
                        backgroundImage: "linear-gradient(#FFFFFF, #EFEFEF)",
                        borderRadius: "15px",
                      }}
                    >
                      <div className="solution-card-solution" key={index}>
                        <div
                          className="in-border"
                          style={{
                            alignContent: "center",
                            height: "90px",
                            width: "90px",
                            // padding: "5px",
                            borderColor: "yellow",
                            border: "2px solid #E97730",
                            borderRadius: "50%",
                            // display: "table",
                            margin: "auto",
                            backgroundColor: "transparent",
                            marginTop: "-32%",
                            padding: "0",
                            position: "relative",
                            top: "1rem",
                          }}
                        >
                          <div
                            className="in-border"
                            style={{
                              height: "80px",
                              width: "80px",
                              padding: "10px",
                              borderColor: "yellow",
                              border: "2px solid #E97730",
                              borderRadius: "50%",
                              // display: "table",
                              margin: "auto",
                              backgroundColor: "#E97730",
                            }}
                          >
                            <figure>
                              <img src={item.img} alt={item.img} />
                            </figure>
                          </div>
                        </div>
                        <div className="content-solution">
                          <h3
                            style={{
                              fontSize: "20px",
                              fontWeight: "500",
                              color: "#2D394B",
                              textAlign: "center",
                              margin: "35px 0 10px",
                            }}
                          >
                            {item.title}
                          </h3>
                          <p className="description-solution-product">
                            Lorem ipsum dolor sit amet consectetur adipiscing
                            elit nulla elementum sit amet metus
                          </p>
                        </div>
                        <div className="log-in" style={{ marginLeft: "30%" }}>
                          <a href="/blogmore" className="media-link">
                            <div
                              className="media-banner"
                              style={{ width: "auto" }}
                            >
                              <img
                                className="normal-banner"
                                src="/images/read-more-btn.png"
                                alt=""
                              />
                              <img
                                className="hover-img-banner"
                                src={"/images/orange-btn.png"}
                                alt=""
                              />
                              <span
                                className="login-text"
                                style={{
                                  fontSize: "20px",
                                  color: "white",
                                  top: "46%",
                                }}
                                onMouseOver={(e) =>
                                  (e.target.style.color = "#07528B")
                                } // Change color on hover
                                onMouseOut={(e) =>
                                  (e.target.style.color = "white")
                                }
                              >
                                Read More
                              </span>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Row>
          <PageControl
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </>
      )}
    </div>
  );
};

export default ProductDetailsUbantu;
